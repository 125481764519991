// Use interstate font for registered symbols
document.querySelectorAll('sup').forEach(sup => {
  sup.innerHTML = sup.innerHTML.replace(
    /(®|&reg;)/g,
    '<span class="sans-serif">$1</sup>'
  );
});

// Replace <sup> tags in select boxes with unicode characters
const specialCharacters = {
  '+': '&#8314;',
  '*': '*'
};

document.querySelectorAll('select option').forEach(option => {
  const match = option.innerText.match(/<sup>(.*)<\/sup>/);

  if (match) {
    const nonUnicodeCharacters = [];
    let characters = match[1]
      .split(/,?/)
      .map(c => {
        if (!isNaN(c)) {
          return `&sup${c}`;
        }

        if (specialCharacters[c]) {
          nonUnicodeCharacters.push(c);
        }

        return specialCharacters[c] || c;
      })
      .filter(c => c);

    if (nonUnicodeCharacters.length) {
      characters.unshift(nonUnicodeCharacters);
      characters = characters.flat();
    }

    option.innerHTML = option.innerText.replace(match[0], characters.join(' '));
  }
});
