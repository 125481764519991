import { baseEndpoint } from './search-constants';
import Spinner from '../loading-spinner';
import { uniqueId } from 'lodash';

const debounceTime = 120;
const clearDelay = 200;
const spinnerShowDelay = 200;
const minSpinnerTime = 250;
const maxCachedResults = 30;
const minCharacters = 2;
const shouldScrollToResults = true;

document.querySelectorAll('.inline-search-wrapper').forEach(wrapper => {
  const searchBoxId = uniqueId();
  const input = wrapper.querySelector('.inline-search-input');
  const resultsWrapper = wrapper.querySelector('.inline-search-results-wrapper');
  const announcer = wrapper.querySelector('.inline-search-announcer');

  if (!input || !resultsWrapper || !announcer) {
    console.warn('Inline search wrapper missing required elements');
    return;
  }

  resultsWrapper.innerHTML = '';

  const spinner = new Spinner({
    parent: input.parentElement,
    size: 15,
    strokeWidth: 6
  });

  const url = new URL(baseEndpoint.replace(/\/$/, ''), window.location.origin);
  const searchId = wrapper.dataset.search;
  if (searchId) url.searchParams.set('s', searchId);

  // No results message element
  const noResultsText = wrapper.dataset.noResultsText || 'No results found';
  wrapper.removeAttribute('data-no-results-text');

  const noResultsElement = document.createElement('div');
  noResultsElement.className = 'inline-search-message inline-search-no-results';
  noResultsElement.innerText = noResultsText;

  // Error message element
  const errorElement = document.createElement('div');
  errorElement.className = 'inline-search-message inline-search-result-error';
  errorElement.innerText = 'Something went wrong, please try again later';

  ////////////////////////////
  // Search
  ////////////////////////////

  let isOpen = false;
  let isHovering = false;

  let activeIndex = null;
  let resultElements = [];
  let prevResults = [];

  let cachedResults = {};
  let currentQuery = '';
  let abortController = null;

  let hasInitialResultsAfterFocus = false;

  const search = async query => {
    if (query === currentQuery) return;
    currentQuery = query;

    // Use cached results if found
    if (cachedResults[query]) {
      setIsLoading(false, query);
      addResults(cachedResults[query]);
      return;
    }

    // Cancel previous request
    if (window.AbortController) {
      abortController?.abort();
      abortController = new AbortController();
    }

    url.searchParams.set('q', query);

    setIsLoading(true);

    try {
      const response = await fetch(url.href, {
        signal: abortController?.signal
      });

      const data = await response.json();
      const isValid = response.ok && Array.isArray(data?.results);
      const isCurrentQuery = query === currentQuery;

      if (!isValid) {
        if (isCurrentQuery) handleError();
        return;
      }

      const results = filterResults(data.results);

      cacheResults(query, results);

      if (isCurrentQuery) {
        await setIsLoading(false, query);
        addResults(results);
      }
    } catch (e) {
      if (e.name === 'AbortError') return;
      if (query === currentQuery) setIsLoading(false, query);

      console.error(e);
      handleError();
    }
  };

  let searchTimer = null;
  let clearResultsTimer = null;

  // When the input empty, unless the user typed
  // backspace, we delay clearing the results to
  // avoid flickering as the user is typing
  let backspacePressed = false;

  const debouncedSearch = () => {
    clearTimeout(searchTimer);
    clearTimeout(clearResultsTimer);

    resultsWrapper.classList.remove('force-hidden');

    const query = input.value.trim().toLowerCase();

    if (query.length < minCharacters) {
      if (!backspacePressed && currentQuery.length >= minCharacters) {
        clearResultsTimer = setTimeout(clearResults, clearDelay);
      } else {
        clearResults();
      }

      backspacePressed = false;
      currentQuery = query;
      abortController?.abort();

      return;
    }

    searchTimer = setTimeout(() => search(query), debounceTime);
  };

  ////////////////////////////
  // Result helpers
  ////////////////////////////

  let isLoadingTimer = null;
  let isLoadingPromise = null;

  const setIsLoading = async (isLoading, query = null) => {
    if (isLoading) {
      // Only show spinner after reaching spinnerShowDelay threshold
      if (isLoadingTimer === null) {
        isLoadingTimer = setTimeout(() => {
          wrapper.classList.add('loading');
          spinner.show();
          isLoadingTimer = null;

          // Once spinner is shown, ensure it stays visible for
          // at least minSpinnerTime milliseconds to avoid flickering
          if (isLoadingPromise === null) {
            isLoadingPromise = new Promise(resolve => {
              setTimeout(() => {
                resolve();
                isLoadingPromise = null;
              }, minSpinnerTime);
            });
          }
        }, spinnerShowDelay);
      }
    } else {
      clearTimeout(isLoadingTimer);
      await isLoadingPromise;

      if (query !== null && query !== currentQuery) {
        return;
      }

      wrapper.classList.remove('loading');
      spinner.hide();
    }
  };

  const clearResults = () => {
    activeIndex = null;
    resultElements = [];
    prevResults = [];
    resultsWrapper.innerHTML = '';
    announcer.innerText = 'Cleared search results.';
    input.setAttribute('aria-expanded', false);
    resultsWrapper.classList.remove('force-hidden');
  };

  const announceResults = numResults => {
    announcer.innerText =
      numResults === 1 ? '1 search result found.' : `${numResults} search results found.`;
  };

  const addResults = results => {
    resultsWrapper.classList.remove('force-hidden');
    resultsWrapper.removeAttribute('aria-activedescendant');

    input.setAttribute('aria-expanded', currentQuery.length >= minCharacters);

    if (!Array.isArray(results) || !results.length) {
      activeIndex = null;
      resultElements = [];
      prevResults = [];
      resultsWrapper.innerHTML = '';
      resultsWrapper.append(noResultsElement);
      announcer.innerText = 'No search results found.';
      return;
    }

    // If results are the same as previous query, do not re-render
    const isSameResults =
      prevResults.length !== 0 &&
      results.length === prevResults.length &&
      results.every((r, i) => {
        return r.pageName === prevResults[i].pageName && r.path === prevResults[i].path;
      });

    if (isSameResults) return;

    resultElements = [];
    activeIndex = null;
    prevResults = results;

    announceResults(results.length);

    const resultsFragment = document.createDocumentFragment();

    results.forEach((result, i) => {
      const el = document.createElement('a');

      el.innerText = result.pageName;
      el.href = result.path.toLowerCase();
      el.id = `inline-search-item-${searchBoxId}-${i}`;
      el.className = 'inline-search-result';
      el.tabIndex = -1;
      el.role = 'option';
      el.setAttribute('aria-selected', false);
      el.setAttribute('aria-setsize', results.length);
      el.setAttribute('aria-posinset', i + 1);

      const icon = document.createElement('span');
      icon.className = 'inline-search-result-icon';
      el.prepend(icon);

      resultElements.push(el);
      resultsFragment.append(el);
    });

    resultsWrapper.innerHTML = '';
    resultsWrapper.scrollTop = 0;
    resultsWrapper.append(resultsFragment);

    if (!hasInitialResultsAfterFocus) {
      hasInitialResultsAfterFocus = true;
      scrollToResults();
    }
  };

  const filterResults = results => {
    return Array.isArray(results) ? results.filter(r => !!r.pageName && !!r.path) : [];
  };

  const cacheResults = (query, results) => {
    const cacheKeys = Object.keys(cachedResults);

    // Delete half of the cached results if the cache is full
    if (cacheKeys.length >= maxCachedResults) {
      for (let i = 0; i < Math.floor(cacheKeys.length / 2); i++) {
        delete cachedResults[cacheKeys[i]];
      }
    }

    cachedResults[query] = results;
  };

  const handleError = () => {
    resultsWrapper.innerHTML = '';
    resultsWrapper.append(errorElement);

    resultsWrapper.removeAttribute('aria-activedescendant');
    resultsWrapper.classList.remove('force-hidden');

    announcer.innerText = 'An error occurred while searching.';
  };

  const scrollToResults = () => {
    const shouldScroll =
      typeof window.inlineSearchScrollToResults === 'boolean'
        ? window.inlineSearchScrollToResults
        : shouldScrollToResults;

    if (!shouldScroll || !isOpen) return;

    const { bottom: resultsBottom } = resultsWrapper.getBoundingClientRect();

    if (resultsBottom > window.innerHeight) {
      window.scrollTo({
        top: window.scrollY + resultsBottom - window.innerHeight + 12,
        behavior: 'smooth'
      });
    }
  };

  ////////////////////////////
  // Keyboard navigation
  ////////////////////////////

  let activeElementWasChangedByMouse = false;
  let activeIndexBeforeMouseout = null;

  const setActiveElement = (index, isKeyboard = true) => {
    const numElements = resultElements.length;
    const hasQuery = currentQuery.length >= minCharacters;

    input.setAttribute('aria-expanded', hasQuery);

    if (index === null || !numElements) {
      if (activeElementWasChangedByMouse) {
        activeIndexBeforeMouseout = activeIndex;
      }

      if (hasQuery && !numElements) {
        resultsWrapper.classList.remove('force-hidden');
      }

      activeIndex = null;

      resultsWrapper.removeAttribute('aria-activedescendant');
      resultElements.forEach(el => {
        el.classList.remove('active');
        el.setAttribute('aria-selected', false);
      });

      return;
    }

    resultsWrapper.classList.remove('force-hidden');

    index = ((index % numElements) + numElements) % numElements;

    activeElementWasChangedByMouse = !isKeyboard;

    resultElements.forEach((el, i) => {
      const isActive = i === index;

      el.classList.toggle('active', isActive);
      el.setAttribute('aria-selected', isActive);

      if (isActive) {
        // The parent element of the results is a scrollable div.
        // If the element is out of view, we need to scroll it into view
        if (isKeyboard) {
          if (i === 0) {
            resultsWrapper.scrollTop = 0;
          } else if (i === numElements - 1) {
            resultsWrapper.scrollTop = resultsWrapper.scrollHeight;
          } else {
            el.scrollIntoView({ behavior: 'instant', block: 'nearest' });
          }
        }

        // Set aria-activedescendant attribute to this element
        if (el.id) {
          resultsWrapper.setAttribute('aria-activedescendant', el.id);
        } else {
          resultsWrapper.removeAttribute('aria-activedescendant');
        }
      }
    });

    activeIndex = index;
  };

  document.addEventListener('keydown', e => {
    if (!isOpen && !isHovering) return;

    const prevIndex = activeElementWasChangedByMouse
      ? activeIndexBeforeMouseout
      : activeIndex;

    switch (e.key) {
      case 'Escape':
        if (currentQuery.length >= minCharacters) {
          resultsWrapper.classList.add('force-hidden');
          input.setAttribute('aria-expanded', false);
        }

        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveElement((prevIndex ?? 0) - 1);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setActiveElement(prevIndex === null ? 0 : prevIndex + 1);
        break;
      case 'Enter':
        e.preventDefault();

        if (activeIndex !== null) {
          resultElements[activeIndex]?.click();
        }

        break;
    }
  });

  ////////////////////////////
  // Events
  ////////////////////////////

  // Prevent form submission
  wrapper.querySelectorAll('form').forEach(form => {
    form.addEventListener('submit', e => e.preventDefault());
  });

  // Search after the user stops typing for debounceTime milliseconds
  input.addEventListener('input', debouncedSearch);

  // Unhide results when input is focused
  input.addEventListener('focus', () => {
    isOpen = true;
    resultsWrapper.classList.remove('force-hidden');

    const hasQuery = currentQuery.length >= minCharacters;

    input.setAttribute('aria-expanded', hasQuery);

    if (hasQuery && activeIndex !== null && resultElements[activeIndex]) {
      resultsWrapper.setAttribute(
        'aria-activedescendant',
        resultElements[activeIndex].id
      );
    }

    if (resultElements.length) {
      hasInitialResultsAfterFocus = true;
      scrollToResults();
    }
  });

  input.addEventListener('blur', () => {
    isOpen = false;
    isHovering = false;
    hasInitialResultsAfterFocus = false;
    input.setAttribute('aria-expanded', false);
    resultsWrapper.removeAttribute('aria-activedescendant');
  });

  // Hide results without delay when user clears input
  input.addEventListener('keydown', e => {
    if (e.key === 'Backspace') backspacePressed = true;
  });

  // Prevent input from losing focus when clicking on results wrapper
  resultsWrapper.addEventListener('mousedown', e => e.preventDefault());

  // Set active element when hovering over results
  resultsWrapper.addEventListener('mouseover', e => {
    const element = e.target.closest('.inline-search-result');
    if (!element) return;

    const index = resultElements.indexOf(element);
    if (index !== -1) setActiveElement(index, false);
  });

  resultsWrapper.addEventListener('mouseenter', () => (isHovering = true));
  resultsWrapper.addEventListener('mouseleave', () => {
    isHovering = false;

    if (activeElementWasChangedByMouse) {
      setActiveElement(null, false);
    }
  });
});
