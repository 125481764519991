const select = document.querySelector('.comparison-table select');
const moreFeatures = document.querySelector('.more-features');
const cells = document.querySelectorAll(
  '.comparison-table tr:not(.more-features) td:not(.invisible)'
);
const visibleRows = document.querySelectorAll(
  '.comparison-table tbody tr:not(.more-features):not(.learn-more-links)'
).length;

function hideSeeMoreFeatures() {
  if (visibleRows <= 4) {
    moreFeatures.classList.add('d-none');
  }
}

function handleCellVisibility() {
  const currentWidth = window.innerWidth;

  if (currentWidth > 991) {
    cells.forEach(function (cell) {
      cell.classList.add('d-lg-table-cell');
    });
  }
}

// update to prevent column(s) expanding when 'see more features' is expanded on desktop
function setProductTypeColumnWidths() {
  const comparisonTable = document.querySelector('.comparison-table');

  if (comparisonTable !== null) {
    const headerCells = comparisonTable.querySelectorAll(
      '.desktop th:not(:first-of-type):not(.invisible)'
    );

    if (headerCells.length > 0) {
      const thTotalItems = headerCells.length;
      let thWidthTotal = 0;

      // get total width of select header cells
      headerCells.forEach(function (th) {
        thWidthTotal += th.clientWidth;
      });

      // set width of each header cell
      const updateThColWidth = thWidthTotal / thTotalItems;
      headerCells.forEach(function (th) {
        th.setAttribute('style', 'width: ' + updateThColWidth + 'px');
      });
    }
  }
}

if (select !== null) {
  select.addEventListener('change', function (e) {
    var selectedItem = e.currentTarget.value;

    cells.forEach(cell => {
      if (!cell.classList.contains('th')) {
        if (cell.classList.contains(selectedItem)) {
          cell.classList.remove('d-none');
        } else {
          cell.classList.add('d-none');
        }
      }
    });
  });
}

if (cells.length) {
  window.addEventListener('resize', handleCellVisibility);

  document.addEventListener('DOMContentLoaded', () => {
    setProductTypeColumnWidths();
    visibleRows ? hideSeeMoreFeatures() : '';
  });
}
