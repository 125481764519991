import $ from 'jquery';

const videoModalTriggers = document.querySelectorAll('.video-container');

videoModalTriggers.forEach(function (modalTrigger) {
  const dataTarget = modalTrigger.dataset.target;
  const modal = $(dataTarget);
  const iframe = modal.find('iframe');

  if (!iframe.length) {
    return;
  }

  const src = iframe.attr('data-src');

  const play = () => {
    iframe[0].contentWindow.postMessage(
      '{"event":"command", "func":"playVideo", "args":""}',
      '*'
    );
  };

  const pause = () => {
    iframe[0].contentWindow.postMessage(
      '{"event":"command", "func":"pauseVideo", "args":""}',
      '*'
    );
  };

  if (src) {
    let parts = src.split('?');
    let url = parts[0];

    const params = (parts[1] ?? '').split('&');

    const addParam = (name, value) => {
      const regex = new RegExp(name, 'i');

      if (!params.find(p => regex.test(p))) {
        params.push(`${name}=${value}`);
      }
    };

    const defaultParams = {
      enablejsapi: 1,
      rel: 0,
      modestbranding: 1
    };

    Object.keys(defaultParams).forEach(key => {
      addParam(key, defaultParams[key]);
    });

    url += `?${params.join('&')}`;

    $(modalTrigger).one('click', () => {
      iframe.attr('src', url);
      iframe.on('load', () => {
        play();
        modal.on('shown.bs.modal', play);
      });
    });
  }

  modal.on('hide.bs.modal', pause);
});
