const noResultsTextEl = document.querySelector('#no-results-text');
const noResultsText = noResultsTextEl ? noResultsTextEl.textContent : '';

const predictiveSearch = document.querySelector('.predictive-search');
const autoCompleteInput = predictiveSearch
  ? predictiveSearch.querySelector('#autoComplete')
  : '';

if (autoCompleteInput) {
  new autoComplete({
    data: {
      // Data src [Array, Function, Async] | (REQUIRED)
      src: async () => {
        // API key token
        const token = '';

        // User search query
        const query = document.querySelector('#autoComplete').value;

        // Fetch External Data Source
        const source = await fetch(`/apis/FaqSearchApi/search?key=${token}&q=${query}`);
        //const source = await fetch('templates-data/data.json');

        // Format data into JSON
        const data = await source.json();

        // Return Fetched data
        return data.questions;
      },
      key: ['question'],
      cache: false
    },
    sort: (a, b) => {
      // Sort rendered results ascendingly | (Optional)
      if (a.match < b.match) return -1;
      if (a.match > b.match) return 1;
      return 0;
    },
    placeHolder: 'Enter your search term', // Place Holder text                 | (Optional)
    selector: '#autoComplete', // Input field selector              | (Optional)
    threshold: 0, // Min. Chars length to start Engine | (Optional)
    debounce: 300, // Post duration for engine to start | (Optional)
    searchEngine: 'strict', // Search Engine type/mode           | (Optional)
    resultsList: {
      // Rendered results list object      | (Optional)
      render: true,
      /* if set to false, add an eventListener to the selector for event type
          "autoComplete" to handle the result */
      container: source => {
        source.setAttribute('id', 'autoComplete_results');
        source.setAttribute('class', 'list-unstyled text-left');
      },
      destination: document.querySelector('#autoComplete'),
      position: 'afterend',
      element: 'ul'
    },
    maxResults: 5, // Max. number of rendered results | (Optional)
    highlight: true, // Highlight matching results      | (Optional)
    resultItem: {
      // Rendered result item            | (Optional)
      content: (data, source) => {
        // const faqParam = '?faq=' + data.value.id;
        // const param = faqParam;
        // source.innerHTML =
        //   "<a href='" + faqDetailUrl + param + "'>" + data.match + '</a>';
        const url = data.value.url.replace(/([^:])\/{2,}/g, '$1/');
        source.innerHTML = `<a href="${url}">${data.match}</a>`;
      },
      element: 'li' // Action script on noResults      | (Optional)
    },
    noResults: () => {
      // Action script on noResults      | (Optional)
      const result = document.createElement('li');
      result.setAttribute('class', 'no_result');
      result.setAttribute('tabindex', '1');
      result.innerHTML = noResultsText;
      document.querySelector('#autoComplete_results').appendChild(result);
    }
    // onSelection: feedback => {
    //   // Action script onSelection event | (Optional)
    //   const faqId = feedback.selection.value.id;
    //   const param = '?faq=' + faqId;
    //   window.location.href = faqDetailUrl + param;
    // }
  });
}
