import tracker from './analytics';

initGlia();

function initGlia() {
  if (!window.sm) {
    const script = document.querySelector('script[src*="salemove_integration.js"]');

    if (script) script.addEventListener('load', initGlia);

    return;
  }

  window.sm.getApi({ version: 'v1' }).then(function (glia) {
    glia.addEventListener(glia.EVENTS.ENGAGEMENT_START, function (engagement) {
      tracker.track('glia_chat_start', { chat_type: engagement.startingMedia });
    });

    glia.addEventListener(glia.EVENTS.ENGAGEMENT_END, function (engagement) {
      tracker.track('glia_chat_end', {
        chat_type: engagement.startingMedia,
        chat_duration: formatDuration(engagement.createdAt)
      });
    });
  });
}

function formatDuration(startTimeISO) {
  const startTime = new Date(startTimeISO);
  const endTime = new Date();
  const MS_IN_MINUTE = 1000 * 60;
  let formatted = '';
  let durationMS = endTime - startTime;

  if (durationMS >= MS_IN_MINUTE) {
    formatted += `${Math.floor(durationMS / MS_IN_MINUTE)}m `;
    durationMS %= MS_IN_MINUTE;
  }

  formatted += `${Math.floor(durationMS / 1000)}s`;
  return formatted;
}
