export default function addInternetExplorerBanner() {
  $('main').prepend(`<div class="ie-banner">
    <div class="content-wrapper">
      <div class="ie-banner-content">
        <div class="ie-banner-img">
          <img
            alt="internet explorer logo"
            src="/FremontBank/media/Images/Logos/IE_logo.png"
          />
        </div>
  
        <div class="ie-banner-text">
          <h2>Your browser is out of date</h2>
          <p>
            Due to safety and performance concerns, Microsoft no longer supports Internet Explorer.
            In order to ensure that you have the best possible experience on our site,
            please update to a modern browser like <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a> or
            <a href="https://www.google.com/chrome/">Google Chrome</a>.
          </p>
        </div>
      </div>
    </div>
  </div>`);
}
