export default class Spinner {
  constructor({
    className,
    parent,
    shouldAttach = true,
    strokeWidth = 3,
    size = 50,
    color
  }) {
    this.spinner = document.createElement('div');
    this.spinner.className = `spinner ${className || ''}`.trim();

    const sizeOuter = `${size}px`;
    this.spinner.style.width = sizeOuter;
    this.spinner.style.height = sizeOuter;

    const sizeInner = (size * 100) / 40 - (size * 20) / 100;

    this.spinner.innerHTML = `
      <svg width="${sizeInner}" height="${sizeInner}" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="20" fill="none" stroke-width="${strokeWidth}" stroke-miterlimit="10"/>
      </svg>
    `;

    if (color) {
      this.spinner.style.color = color;
    }

    this.isVisible = false;
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.attach = this.attach.bind(this);
    this.remove = this.remove.bind(this);

    this.parent = typeof parent === 'string' ? document.querySelector(parent) : parent;

    if (this.parent && shouldAttach) {
      this.attach();
    }

    document.addEventListener('visibilitychange', () => {
      if (this.isVisible && this.isAttached) {
        if (document.visibilityState === 'visible') {
          this.spinner.classList.remove('paused');
        } else {
          this.spinner.classList.add('paused');
        }
      }
    });
  }

  attach(parent = this.parent) {
    if (typeof parent === 'string') {
      parent = document.querySelector(parent);
    }

    if (parent instanceof HTMLElement && (parent !== this.parent || !this.isAttached)) {
      this.isAttached = true;
      this.parent.appendChild(this.spinner);
    }
  }

  show(parent) {
    this.attach(parent);
    this.spinner.classList.add('visible');

    if (this.isAttached) {
      this.isVisible = true;
      this.spinner.style.display = 'block';
    }
  }

  hide() {
    this.isVisible = false;
    this.spinner.classList.remove('visible');
    this.spinner.style.display = 'none';
  }

  remove() {
    if (this.isAttached) {
      this.isAttached = false;
      this.isVisible = false;
      this.spinner.parentElement.removeChild(this.spinner);
    }
  }
}
