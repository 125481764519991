document
  .querySelectorAll('.main-nav .toggle-submenu-button')
  .forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();

      const expanded =
        button.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';

      button.setAttribute('aria-expanded', expanded);
      button.parentElement.setAttribute('data-expanded', expanded);
    });
  });
