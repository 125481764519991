export function upperFirst(str) {
  if (typeof str !== 'string') {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getUrlParameter(name) {
  return window.location.search
    .substring(1)
    .split('&')
    .find(s => s.split('=')[0] === name)
    ?.split('=')[1]
    ?.trim();
}

export function scrollToContainer(urlHash) {
  if (urlHash.match(/\?/)) {
    return;
  }

  const headerHeight = document.querySelector('#header').clientHeight;
  const el = document.querySelector(urlHash);

  if (!el) {
    return;
  }

  const elWithHashIdOffset = el.getBoundingClientRect().top;
  const yOffset = elWithHashIdOffset - headerHeight;

  if (elWithHashIdOffset) {
    setTimeout(() => {
      window.scrollTo(0, yOffset);
    }, 0);
  }
}

export function throttle(func, limit = 50) {
  let inThrottle;

  return function (...args) {
    const context = this;

    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export function isPageBuilder() {
  return document.body.classList.contains('KenticoAdminPageBuilder');
}

export function createElement(type, attributes) {
  const el = document.createElement(type);
  Object.entries(attributes).forEach(([key, value]) => {
    if (key === 'attributes') {
      value.forEach(([attr, val]) => el.setAttribute(attr, val));
    } else {
      el[key] = value;
    }
  });

  return el;
}
