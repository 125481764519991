const marketoFormEls = Array.from(document.querySelectorAll('.mkto-form[data-formId]'));
const totalForms = marketoFormEls.length;
let formsRendered = 0;

/* config area - replace with your instance values */

const mktoFormConfig = {
  podId: '//app-sj06.marketo.com',
  munchkinId: '614-UNR-911',
  formIds: getUniqueFormIds(marketoFormEls)
};

if (totalForms) {
  addMarketoScript(mktoFormConfig);
}

/* ---- FUNCTIONS ---- */

function addMarketoScript(config) {
  const script = document.createElement('script');
  script.src = `${config.podId}/js/forms2/js/forms2.min.js`;
  script.type = 'text/javascript';
  script.onload = () => {
    mktoFormChain(config);
  };
  document.body.appendChild(script);
}

function mktoFormChain(config) {
  MktoForms2.whenRendered(form => {
    formsRendered++;
    destyleMktoForm(form);
    onFormSubmit(form);
    /* fix inter-form label bug */
    createUniqueInputIds(form);
  });

  /* chain, ensuring only one #mktoForm_nnn exists at a time */
  config.formIds.forEach(formId => {
    const loadForm = MktoForms2.loadForm.bind(
      MktoForms2,
      config.podId,
      config.munchkinId,
      formId
    );

    const formEls = marketoFormEls.filter(
      el => Number(el.getAttribute('data-formId')) === formId
    );

    (function loadFormCb(formEls) {
      const formEl = formEls.shift();
      formEl.id = 'mktoForm_' + formId;

      loadForm(() => {
        formEl.id = '';
        if (formEls.length) {
          loadFormCb(formEls);
        }
      });
    })(formEls);
  });
}

function onFormSubmit(mktoForm) {
  const formEl = mktoForm.getFormElem()[0];
  const message = formEl.dataset.message?.trim() || 'Thank you for signing up!';
  const redirectUrl = formEl.dataset.redirect?.trim() || null;
  mktoForm.onSuccess(() => {
    if (redirectUrl) {
      location.href = redirectUrl;
    } else {
      mktoForm.getFormElem().hide();
      const node = document.createElement('div');
      node.classList.add('mkto-message-wrapper');
      node.innerHTML = `
            <span class="mkto-message">
                ${message}
            </span>
        `;
      formEl.parentNode.appendChild(node);
    }

    // Return false to prevent the submission handler from taking the lead to the follow up url
    return false;
  });
}

function destyleMktoForm(mktoForm) {
  const formEl = mktoForm.getFormElem()[0];

  // remove element styles from <form> and children
  const styledEls = Array.from(formEl.querySelectorAll('[style]')).concat(formEl);
  styledEls.forEach(el => {
    el.removeAttribute('style');
  });

  const styleSheets = Array.from(document.styleSheets);
  styleSheets.forEach(ss => {
    if (formEl.contains(ss.ownerNode)) {
      ss.disabled = true;
    }
    /* disable remote stylesheets only after last form is rendered
        to avoid marketo timeout issue https://blog.teknkl.com/whats-the-deal-with-mktostyleloaded-div/ */
    if (
      formsRendered === totalForms &&
      [mktoForms2BaseStyle, mktoForms2ThemeStyle].indexOf(ss.ownerNode) != -1
    ) {
      ss.disabled = true;
    }
  });

  // makes forms visible
  if (formsRendered === totalForms) {
    marketoFormEls.forEach(form => {
      form.setAttribute('data-styles-ready', 'true');
    });
  }

  formEl.querySelector('button[type="submit"]').classList.add('btn', 'btn-primary');
}

function createUniqueInputIds(mktoForm) {
  const formEl = mktoForm.getFormElem()[0],
    rando = '_' + new Date().getTime() + Math.random();

  formEl.querySelectorAll('label[for]').forEach(labelEl => {
    const forEl = formEl.querySelector('[id="' + labelEl.htmlFor + '"]');
    if (forEl) {
      labelEl.htmlFor = forEl.id = forEl.id + rando;
    }
  });
}

function getUniqueFormIds(mktoForms) {
  return mktoForms
    .map(formEl => Number(formEl.getAttribute('data-formId')))
    .reduce((acc, nextFormId) => {
      if (acc.indexOf(nextFormId) == -1) {
        acc.push(nextFormId);
      }
      return acc;
    }, []);
}
