import {
  createResult,
  createTopResult,
  createResultsTitle,
  createLoadMoreButton,
  createBestBetWrapper,
  createBestBetLinksWrapper,
  generateId,
  createAndPositionFixedElements
} from './helpers';
import { baseEndpoint, baseSettingsEndpoint } from './search-constants';
import SearchTracker from './search-tracker';
import { disableScroll, enableScroll, getScrollbarWidth } from '../scrolling';
import Spinner from '../loading-spinner';
import { prefersReducedMotion, reducedMotionMediaQuery, requestFrame } from '../ada';

class SiteSearch {
  /////////////////////////////////////
  // Public fields
  /////////////////////////////////////

  debounceTime = 120;
  debounceTimeInitial = 350;
  queryTrackingTimeout = 2000;
  loadingSpinnerDelay = 200;
  minCharacters = 2;
  resetOnClose = true;
  mobileSize = 890;

  /////////////////////////////////////
  // Private fields
  /////////////////////////////////////

  #settings = {};
  #isOpen = false;
  #hasInitialSearch = false;
  #transitionCount = 0;
  #queryId = null;
  #data = {};
  #searchTimer = null;
  #loadingTimer = null;
  #openCallbacks = [];
  #openCompleteCallbacks = [];
  #closeCallbacks = [];
  #closeCompleteCallbacks = [];
  #focusElements = {};
  #returnFocusElement = null;
  #resultsOffset = null;
  #abortController = null;
  #errorElement = null;
  #hasError = false;
  #scrollbarWidth = 0;
  #scrollDisabledExternally = true;

  constructor({
    wrapper,
    trigger,
    close,
    results,
    bestBetResults,
    noResults,
    noInput,
    announcer,
    search = 'input[type="search"]',
    loadMoreText = 'Load more results',
    loadMoreIsLoadingText = 'Loading'
  }) {
    this.wrapper = document.querySelector(wrapper);
    this.triggers = document.querySelectorAll(trigger);
    this.closeButton = document.querySelector(close);
    this.resultsWrapper = document.querySelector(results);
    this.noResults = document.querySelector(noResults);
    this.noInput = document.querySelector(noInput);
    this.input = this.wrapper?.querySelector(search);
    this.announcer = document.querySelector(announcer);

    if (bestBetResults) {
      this.bestBetWrapper = document.querySelector(bestBetResults);
    }

    if (
      !this.wrapper ||
      !this.triggers.length ||
      !this.closeButton ||
      !this.resultsWrapper ||
      !this.input ||
      !this.noResults ||
      !this.noInput
    ) {
      console.error('Required search elements not found.');
      return;
    }

    this.tracker = new SearchTracker(this);
    this.spinner = new Spinner({ parent: this.wrapper });
    this.loadMoreText = loadMoreText;
    this.loadMoreIsLoadingText = loadMoreIsLoadingText;
    this.#scrollbarWidth = getScrollbarWidth();

    // Bind instance methods to this
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.search = this.search.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.reset = this.reset.bind(this);

    this.#initElements();
    this.#initSettings();
    this.#initAccessibility();
    this.#initEventListeners();
  }

  /////////////////////////////////////
  // Computed properties
  /////////////////////////////////////

  get query() {
    return this.#data[this.#queryId]?.query ?? '';
  }

  get results() {
    return this.#data[this.#queryId]?.links ?? [];
  }

  get currentQuery() {
    return this.#data[this.#queryId] ?? null;
  }

  get cachedResults() {
    return this.#data;
  }

  get isOpen() {
    return this.#isOpen;
  }

  get data() {
    return this.#data;
  }

  get searchSettings() {
    return this.#settings;
  }

  get shouldControlScroll() {
    return (
      !this.#scrollDisabledExternally &&
      !document.body.classList.contains('mobile-menu-open') &&
      !document.body.classList.contains('overlay-open')
    );
  }

  /////////////////////////////////////
  // Public methods
  /////////////////////////////////////

  open(trigger) {
    if (this.#isOpen) {
      return;
    }

    this.#isOpen = true;
    this.#scrollDisabledExternally = document.body.classList.contains('disable-scroll');
    this.#transitionCount = 0;
    this.#returnFocusElement = document.activeElement ?? this.triggers[0];
    this.wrapper.setAttribute('aria-hidden', 'false');
    this.#openCallbacks.forEach(c => c());

    if (this.shouldControlScroll) {
      disableScroll();
    }

    const triggerEl = trigger ?? window;

    triggerEl.dispatchEvent(
      new CustomEvent('globalsearchopen', {
        bubbles: true
      })
    );

    requestFrame(() => {
      this.wrapper.classList.add('animating');
      this.wrapper.classList.add('open');

      const delay = prefersReducedMotion() ? 0 : 500;
      setTimeout(() => this.input.focus(), delay);

      if (prefersReducedMotion()) {
        this.#openCompleteCallbacks.forEach(c => c());
      }
    });
  }

  close() {
    if (!this.#isOpen) {
      return;
    }

    this.#isOpen = false;
    this.#transitionCount = 0;
    this.wrapper.setAttribute('aria-hidden', 'true');
    this.noResults.setAttribute('aria-hidden', 'true');
    this.noInput.setAttribute('aria-hidden', 'true');

    if (this.input === document.activeElement) {
      this.input.parentElement.classList.add('has-focus');
    }

    this.#returnFocusElement?.focus();
    this.#returnFocusElement?.blur();
    this.#updateStatus();
    this.#closeCallbacks.forEach(c => c());

    if (this.shouldControlScroll) {
      enableScroll();
    }

    this.closeButton.dispatchEvent(
      new CustomEvent('globalsearchclose', {
        bubbles: true
      })
    );

    requestFrame(() => {
      this.wrapper.classList.add('animating');
      this.wrapper.classList.remove('open');

      if (prefersReducedMotion()) {
        this.#closeCompleteCallbacks.forEach(c => c());
      }
    });
  }

  search(query, isLoadMore) {
    clearTimeout(this.#searchTimer);

    query = query?.trim() ?? '';
    const hasQuery = query && query.length >= this.minCharacters;

    if (!hasQuery || (query.toLowerCase() === this.query.toLowerCase() && !isLoadMore)) {
      this.#setHasInput(hasQuery);

      if (!hasQuery) {
        this.#queryId = '';

        if (!isLoadMore) {
          this.wrapper.scrollTop = 0;
        }
      }

      return;
    }

    const id = generateId(query);
    this.#queryId = id;

    if (!isLoadMore) {
      this.wrapper.scrollTop = 0;
    }

    // Return cached results if this is a duplicate query
    if (!isLoadMore && this.#data[id] && !this.#data[id].hasError) {
      this.#updateLinks(id, this.#data[id].links, this.#data[id].topResults);
      this.#setHasInput(true);
      return;
    }

    this.#setIsLoading(true);

    if (!isLoadMore || !this.#data[id]) {
      this.#data[id] = {
        query,
        id,
        links: [],
        topResults: [],
        pageNum: 1,
        hasMoreResults: true,
        hasSubmittedFeedback: false,
        hasTrackedQuery: false,
        hasTrackedNoResults: false,
        hasTrackedSuggestedTerm: false
      };
    }

    this.#fetchResults(this.#data[id], isLoadMore);
  }

  loadMore() {
    const currentQuery = this.#data[this.#queryId];

    if (!currentQuery) {
      return;
    }

    currentQuery.pageNum++;

    this.search(currentQuery.query, true);
  }

  reset() {
    if (this.resetOnClose || prefersReducedMotion()) {
      this.#queryId = '';
      this.input.value = '';
      this.#data = {};
      this.#hasInitialSearch = false;
      this.wrapper.classList.remove('has-query');
      this.wrapper.classList.remove('results-open');
      this.resultsWrapper.innerHTML = '';
      this.bestBetResults.innerHTML = '';
      this.bestBetWrapper.classList.remove('hidden');
      this.bestBetWrapper.setAttribute('aria-hidden', 'true');
      this.resultsWrapper.classList.remove('hidden');
      this.wrapper.classList.remove('results-visible');
      this.wrapper.classList.remove('no-results');
      this.wrapper.classList.remove('no-input');
      this.wrapper.classList.remove('save-state');
      this.noInput.setAttribute('aria-hidden', 'true');
      this.#updateStatus();
    } else {
      this.wrapper.classList.add('save-state');
    }

    this.wrapper.classList.remove('open');
    this.wrapper.classList.remove('animating');
    this.#removeErrors();
  }

  /////////////////////////////////////
  // Private methods
  /////////////////////////////////////

  #getSearchEndpoint(query, pageNum = 1) {
    const url = new URL(baseEndpoint.replace(/\/$/, ''), window.location.origin);
    url.pathname += `/${pageNum}`;
    url.searchParams.set('q', query);
    if (this.searchSettings.id) url.searchParams.set('s', this.searchSettings.id);
    return url.href;
  }

  #getSettingsEndpoint() {
    const url = new URL(baseSettingsEndpoint.replace(/\/$/, ''), window.location.origin);
    if (this.searchSettings.id) url.searchParams.set('s', this.searchSettings.id);
    return url.href;
  }

  ////////////////////////////
  // Dynamic search settings
  ////////////////////////////

  #initSettings() {
    const getSettings = () => {
      // First check if settings have been added inline to avoid making request
      const settings = document.querySelector('#global-search-settings');

      if (settings) {
        try {
          this.#settings = JSON.parse(settings.textContent);
          this.#initNoResultsText();
          this.#initSuggestedSearchTerms();
          this.tracker.setFeedbackText();
          return;
        } catch {
          console.warn('Invalid inline search settings JSON');
        }
      }

      // Fetch settings from API if not found inline
      requestIdleCallback(async () => {
        try {
          const response = await fetch(this.#getSettingsEndpoint());
          const json = await response.json();

          if (typeof json !== 'object') {
            return;
          }

          this.#settings = json;
          this.#initNoResultsText();
          this.#initSuggestedSearchTerms();
          this.tracker.setFeedbackText();
        } catch (e) {
          console.error(e);
        }
      });
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', getSettings);
    } else {
      getSettings();
    }
  }

  #initSuggestedSearchTerms() {
    const { suggestedTerms } = this.#settings;

    if (!suggestedTerms?.length) {
      this.resultsWrapper.classList.add('no-suggested-terms');
      return;
    }

    const termWrapper = document.createElement('ul');
    termWrapper.className = 'suggested-search-terms';

    const baseDelay = 400;
    const iterationDelay = suggestedTerms.length > 5 ? 120 : 170;
    const buttons = [];

    suggestedTerms.forEach((term, index) => {
      term = term?.trim();

      if (!term) {
        return;
      }

      const li = document.createElement('li');
      const button = document.createElement('button');
      buttons.push(button);

      button.className = 'search-term';
      button.innerHTML = `<span class="sr-only">Search for</span> ${term}`;

      const delay = baseDelay + iterationDelay * index;
      button.style.animationDelay = `${delay}ms`;

      if (this.input.id) {
        button.setAttribute('aria-controls', this.input.id);
      }

      button.addEventListener('click', e => {
        e.preventDefault();

        if (window.innerWidth > this.mobileSize) {
          button.classList.add('search-term-clicked');
          this.input.focus();
        } else {
          this.input.blur();
        }

        this.input.value = term;
        this.search(term);
        this.tracker.track(SearchTracker.actions.SUGGESTED_TERM, null, true);
      });

      button.addEventListener('mousedown', e => e.preventDefault());
      button.addEventListener('mouseleave', () => {
        button.classList.remove('search-term-clicked');
      });

      li.appendChild(button);
      termWrapper.appendChild(li);
    });

    requestFrame(() => {
      this.input.parentElement.parentElement.appendChild(termWrapper);

      // Add intersection observer to hide suggested searches
      // when the results box has scrolled
      requestIdleCallback(() => {
        let suggestionsVisible = true;
        this.#setFocusElements();

        // In order to prevent hidden results from
        // preventing clicks on results, we set
        // the suggestion wrapper's position to
        // absolute when hidden and adjust the
        // results offset accordingly
        const adjustOffset = () => {
          this.#resultsOffset = termWrapper.offsetHeight;
          this.contentWrapper.style.marginTop = `${this.#resultsOffset}px`;
        };

        adjustOffset();

        // Element used to check if user has scrolled
        const sentinal = document.createElement('div');
        sentinal.classList.add('search-scroll-sentinal');
        this.wrapper.appendChild(sentinal);

        const intersectionObserver = new IntersectionObserver(entries => {
          suggestionsVisible = entries[0].isIntersecting;

          requestFrame(() => {
            if (suggestionsVisible) {
              termWrapper.classList.remove('hidden');
            } else {
              termWrapper.classList.add('hidden');
            }

            buttons.forEach(button => {
              if (suggestionsVisible) {
                button.setAttribute('tabindex', '0');
                button.setAttribute('aria-hidden', 'false');
              } else {
                button.setAttribute('tabindex', '-1');
                button.setAttribute('aria-hidden', 'true');

                if (document.activeElement === button) {
                  button.blur();
                }
              }
            });
          });
        });

        // Adjust position when if height changes
        let resizeObserver = null;

        // ResizeObserver isn't supported by IE
        if (window.ResizeObserver) {
          resizeObserver = new ResizeObserver(adjustOffset);
        }

        this.#openCompleteCallbacks.push(() => {
          requestFrame(() => {
            intersectionObserver.observe(sentinal);
            resizeObserver?.observe(termWrapper);
          });
        });

        this.#closeCompleteCallbacks.push(() => {
          intersectionObserver.unobserve(sentinal);
          resizeObserver?.unobserve(termWrapper);
          termWrapper.classList.remove('hidden');
        });
      });
    });
  }

  #initNoResultsText() {
    const noResultsText = (
      this.#settings.noResultsText ??
      `We weren't able to find any search results that matched your query`
    )?.trim();

    if (noResultsText) {
      this.noResults.innerHTML = `
        <span class="section-header" aria-hidden="true">No Results Found</span>
        ${noResultsText}
      `;
    }
  }

  ////////////////////////////
  // UI Elements
  ////////////////////////////

  #initElements() {
    // Results content
    this.contentWrapper = this.resultsWrapper.parentElement;

    // Load more button
    this.loadMoreButton = createLoadMoreButton(this.loadMoreText);

    // Results title text
    this.resultsTitle = createResultsTitle();
    this.contentWrapper.insertBefore(this.resultsTitle.el, this.resultsWrapper);

    // Best bet search results
    if (!this.bestBetWrapper) {
      this.bestBetWrapper = createBestBetWrapper();
      this.contentWrapper.insertBefore(this.bestBetWrapper, this.resultsTitle.el);
    }

    this.bestBetTitle = createResultsTitle(true);
    this.bestBetWrapper.appendChild(this.bestBetTitle.el);

    this.bestBetResults = createBestBetLinksWrapper();
    this.bestBetWrapper.appendChild(this.bestBetResults);

    // Search feedback tracking
    this.tracker.insertFeedbackElements(this.contentWrapper);

    // Animation and background elements
    const { topShadow, bg } = createAndPositionFixedElements(this.closeButton);

    this.contentWrapper.appendChild(topShadow);
    this.wrapper.appendChild(bg);
    this.bg = bg;
  }

  #initAccessibility() {
    this.wrapper.setAttribute('role', 'dialog');
    this.wrapper.setAttribute('aria-modal', 'true');
    this.wrapper.setAttribute('aria-hidden', 'true');
  }

  #setFocusElements() {
    setTimeout(() => {
      requestIdleCallback(() => {
        const elements = this.wrapper.querySelectorAll(
          'a, button:not([disabled]), input[type="search"]'
        );

        if (elements.length) {
          this.#focusElements.first = elements[0];
          this.#focusElements.last = elements[elements.length - 1];
        } else {
          this.#focusElements = {};
        }
      });
    }, 50);
  }

  #initEventListeners() {
    // Prevent search form from reloading page
    this.wrapper
      .querySelector('form')
      ?.addEventListener('submit', e => e.preventDefault());

    // Open when triggers are clicked
    this.triggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        if (!this.#isOpen) {
          this.open(trigger);

          setTimeout(() => {
            trigger.blur();
            this.input.focus();
          }, 10);
        }
      });
    });

    // Close when close button is clicked
    this.closeButton.addEventListener('mousedown', e => e.preventDefault());
    this.closeButton.addEventListener('click', e => {
      e.preventDefault();
      e.stopPropagation();

      this.close();
    });

    // Close when escape key is pressed
    document.addEventListener('keydown', e => {
      if (this.#isOpen && (e.key === 'Esc' || e.key === 'Escape')) {
        this.close();
      }
    });

    // Prevent overscroll when virtual keyboard opens
    this.wrapper.addEventListener(
      'touchmove',
      e => {
        if (this.isOpen && !this.currentQuery) {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    // If the user clicks a result for the same page they're
    // already on, close the search modal instead of reloading
    this.wrapper.addEventListener('click', e => {
      const link = e.target.closest('a');
      if (!link?.href) return;

      try {
        const url = new URL(link.href.toLowerCase());

        if (
          url.origin === window.location.origin &&
          url.pathname === window.location.pathname.toLowerCase()
        ) {
          e.preventDefault();
          const target = url.hash ? document.querySelector(url.hash) : null;
          this.close();
          window.dispatchEvent(new CustomEvent('mobilemenubeforeclose'));
          // closeMobileMenu();

          if (target) {
            const header = document.querySelector('#header');
            const headerOffset = header ? header.offsetHeight + 20 : 0;
            const scrollY = Math.floor(
              target.getBoundingClientRect().top + window.scrollY - headerOffset
            );
            window.scrollTo(window.scrollX, scrollY);
          }
        }
      } catch {} // eslint-disable-line no-empty
    });

    // Only hide modal after overlays are done animating.
    // IE 11 and iOS Safari don't support TransitionEvent.pseudoElement
    // so instead we only fire this handler when the count of
    // events is even, meaning both :before and :after have finished.
    const runAnimationCallbacks = preventCallbacks => {
      this.wrapper.classList.remove('animating');

      if (!this.#isOpen) {
        document.activeElement?.blur();

        if (!preventCallbacks) {
          this.#closeCompleteCallbacks.forEach(c => c());
        }

        this.reset();
      } else if (!preventCallbacks) {
        this.#openCompleteCallbacks.forEach(c => c());
      }
    };

    this.bg.addEventListener('transitionend', () => {
      this.#transitionCount++;

      if (this.#transitionCount >= 2) {
        runAnimationCallbacks();
      }
    });

    if (reducedMotionMediaQuery?.addEventListener) {
      reducedMotionMediaQuery.addEventListener('change', () => {
        if (!this.#isOpen) {
          this.reset();
          this.#transitionCount = 0;
        } else {
          this.#transitionCount = 2;
        }
      });
    }

    const handleReducedMotion = () => {
      if (prefersReducedMotion()) {
        runAnimationCallbacks(true);
      }
    };

    this.#openCompleteCallbacks.push(handleReducedMotion);
    this.#closeCompleteCallbacks.push(handleReducedMotion);

    // Search when the user enters a query
    this.input.addEventListener('input', e => {
      this.#debouncedSearch(e.target.value);
    });

    this.input.addEventListener('keydown', e => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        e.preventDefault();
      }
    });

    // Load additional results
    this.loadMoreButton.addEventListener('click', this.loadMore);

    // Trap focus inside modal
    this.#setFocusElements();
    this.wrapper.addEventListener('keydown', e => {
      if (!this.#isOpen || e.key !== 'Tab') {
        return;
      }

      if (e.shiftKey) {
        if (document.activeElement === this.#focusElements.first) {
          this.#focusElements.last?.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === this.#focusElements.last) {
          this.#focusElements.first?.focus();
          e.preventDefault();
        }
      }
    });

    // Keep input field focus styles if the user
    // uses alt + tab to switch away from the page
    let focusTimer = null;

    window.addEventListener('blur', () => {
      if (this.#isOpen && this.input === document.activeElement) {
        this.input.parentElement.classList.add('has-focus');
      }
    });

    window.addEventListener('focus', () => {
      if (this.#isOpen) {
        clearTimeout(focusTimer);

        focusTimer = setTimeout(() => {
          this.input.parentElement.classList.remove('has-focus');
        }, 30);
      }
    });

    this.#closeCompleteCallbacks.push(() => {
      this.input.parentElement.classList.remove('has-focus');
    });
  }

  ////////////////////////////
  // Fetch results
  ////////////////////////////

  #setIsLoading(isLoading) {
    clearTimeout(this.#loadingTimer);

    if (isLoading) {
      const startLoading = () => {
        this.wrapper.classList.add('loading');
        this.loadMoreButton.classList.add('loading');
        this.loadMoreButton.innerText = this.loadMoreIsLoadingText;
        this.loadMoreButton.disabled = true;
        this.spinner.show();
      };

      if (!this.#hasInitialSearch && window.innerWidth > this.mobileSize) {
        startLoading();
      } else {
        const delay = prefersReducedMotion() ? 0 : this.loadingSpinnerDelay;
        this.#loadingTimer = setTimeout(startLoading, delay);
      }
    } else {
      this.wrapper.classList.remove('loading');
      this.loadMoreButton.classList.remove('loading');
      this.loadMoreButton.innerText = this.loadMoreText;
      this.loadMoreButton.disabled = false;
      this.spinner.hide();
    }
  }

  #debouncedSearch(query) {
    clearTimeout(this.#searchTimer);

    const queryLower = query.toLowerCase();
    const isDuplicate = !!Object.keys(this.#data).find(key => {
      return this.#data[key].query.toLowerCase() === queryLower;
    });

    if (isDuplicate) {
      this.search(query);
      return;
    }

    const delay = this.#hasInitialSearch ? this.debounceTime : this.debounceTimeInitial;
    this.#searchTimer = setTimeout(() => this.search(query), delay);
  }

  async #fetchResults(data, isLoadMore) {
    if (!data?.query || !data?.id) {
      console.error('Invalid data, unable to search');
      return;
    }

    // Cancel previously sent fetch requests
    this.#abortController?.abort();

    if (window.AbortController) {
      this.#abortController = new AbortController();
    }

    const { pageNum = 1, query, id } = data;

    try {
      const response = await fetch(this.#getSearchEndpoint(query, pageNum), {
        signal: this.#abortController?.signal
      });

      if (response.status !== 200) {
        this.#handleApiError();
        return;
      }

      const json = await response.json();

      this.#abortController = null;

      if (this.#data[id]) {
        this.#removeErrors();

        if (!json?.results.length && !json?.topResults.length) {
          if (this.#queryId === id) {
            if (this.#hasInitialSearch) {
              this.#setNoResults();
              this.#setIsLoading(false);
            } else {
              this.#animateInitialSearch(true);
            }
          }

          this.#updateStatus();

          this.tracker.track(SearchTracker.actions.GET_RESULTS, {
            search_term: query,
            has_results: false
          });

          return;
        }

        this.#data[id].totalPages =
          json?.totalPages && !isNaN(json?.totalPages) ? json.totalPages : 1;

        this.#updateLinks(id, json.results, json.topResults, isLoadMore);

        this.tracker.track(SearchTracker.actions.GET_RESULTS, {
          search_term: query,
          has_results: true
        });
      }
    } catch (e) {
      if (e.name === 'AbortError') {
        return;
      }

      console.error(e);
      this.#handleApiError();
    }
  }

  #updateStatus() {
    if (!this.announcer) return;
    if (!this.isOpen) {
      this.announcer.innerText = '';
      return;
    }

    this.announcer.innerText =
      this.results.length === 1
        ? `1 search result found`
        : `${this.results.length} search results found`;
  }

  #updateLinks(queryId, results, topResults, isLoadMore) {
    const data = this.#data[queryId];

    if (!data) return;

    if (!results || !Array.isArray(results)) {
      console.error('Invalid search results', results);
      return;
    }

    data.links = data.links.concat(results);

    // Remove duplicates
    data.links = data.links
      .map(i => i.nodeId)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(id => data.links.find(({ nodeId }) => id === nodeId));

    data.hasMoreResults = data.pageNum < data.totalPages;

    // If new query has been run, still cache
    // the results but don't update the list
    if (this.#queryId !== queryId) {
      return;
    }

    this.#updateStatus();

    // Generate best bet result elements
    const topResultElements = document.createDocumentFragment();
    data.topResults = Array.isArray(topResults) ? topResults : [];
    data.topResults.forEach(r => topResultElements.appendChild(createTopResult(r)));

    // Generate search result element
    const resultElements = document.createDocumentFragment();
    data.links.forEach(r => resultElements.appendChild(createResult(r)));

    const addLinks = isInitial => {
      const hasResults = !!data.links.length;
      const hasTopResults = !!data.topResults.length;

      this.resultsTitle.query.innerText = data.query;

      if (!hasResults && !hasTopResults) {
        this.#setNoResults();
        return;
      }

      // this.noResults.style.display = 'none';
      this.noResults.setAttribute('aria-hidden', 'true');

      // Best bet results
      this.bestBetResults.innerHTML = '';

      if (hasTopResults) {
        this.bestBetWrapper.classList.remove('hidden');
        this.bestBetWrapper.setAttribute('aria-hidden', 'false');
        this.bestBetResults.appendChild(topResultElements);
      } else {
        this.bestBetWrapper.classList.add('hidden');
        this.bestBetWrapper.setAttribute('aria-hidden', 'true');
      }

      // Regular search results
      this.loadMoreButton.parentElement?.removeChild(this.loadMoreButton);
      this.resultsWrapper.innerHTML = '';

      if (!hasResults) {
        this.resultsWrapper.classList.add('hidden');
        this.resultsWrapper.setAttribute('aria-hidden', 'true');
        this.tracker.refresh(true);

        // If we get here it means there are no regular
        // results, but there ARE best bet results
        this.wrapper.classList.add('only-top-results');
        return;
      }

      if (data.hasMoreResults) {
        resultElements.appendChild(this.loadMoreButton);
      }

      this.tracker.refresh();
      this.wrapper.classList.remove('only-top-results');
      this.resultsWrapper.classList.remove('hidden');
      this.resultsWrapper.setAttribute('aria-hidden', 'false');
      this.resultsWrapper.appendChild(resultElements);

      this.wrapper.classList.remove('no-results');
      this.#setHasInput(true);
      if (
        !isLoadMore &&
        !isInitial &&
        !isNaN(this.#resultsOffset) &&
        this.wrapper.scrollTop > this.#resultsOffset
      ) {
        this.wrapper.scrollTop = this.#resultsOffset - 20;
      }
    };

    if (!this.#hasInitialSearch) {
      addLinks(true);
      this.#animateInitialSearch();
    } else {
      requestFrame(() => {
        addLinks(false);
        this.#setIsLoading(false);
        this.#setFocusElements();
      });
    }
  }

  #animateInitialSearch(hasNoResults) {
    if (this.#hasInitialSearch) {
      return Promise.resolve();
    }

    this.#hasInitialSearch = true;

    return new Promise(resolve => {
      setTimeout(() => {
        requestFrame(() => {
          this.wrapper.classList.add('has-query');

          const delay =
            window.innerWidth > this.mobileSize && !prefersReducedMotion() ? 1000 : 0;

          setTimeout(() => {
            this.wrapper.classList.add('results-open');

            requestFrame(() => {
              this.#setIsLoading(false);
              this.wrapper.classList.add('results-visible');

              if (!this.input.value.trim()) {
                this.#setHasInput(false);
              } else if (hasNoResults) {
                this.#setNoResults();
              }

              this.#setFocusElements();

              resolve();
            });
          }, delay);
        });
      }, 10);
    });
  }

  async #handleApiError() {
    if (this.#hasError) {
      return;
    }

    this.#hasError = true;

    if (!this.#errorElement) {
      this.#errorElement = document.createElement('div');
      this.#errorElement.className = 'search-error-wrapper';

      const errorContent = document.createElement('div');
      errorContent.className = 'search-error';
      errorContent.innerText = 'Unable to fetch search results, please try again later.';

      this.#errorElement.appendChild(errorContent);
    }

    this.resultsWrapper.innerHTML = '';
    this.bestBetResults.innerHTML = '';
    this.wrapper.classList.add('has-error');

    if (this.currentQuery) {
      this.currentQuery.hasError = true;
    }

    if (!this.#hasInitialSearch) {
      await this.#animateInitialSearch();
    }

    this.contentWrapper.insertBefore(
      this.#errorElement,
      this.contentWrapper.firstElementChild
    );

    this.#setIsLoading(false);
  }

  #removeErrors() {
    if (!this.#hasError || !this.#errorElement) {
      return;
    }

    if (this.currentQuery) {
      this.currentQuery.hasError = false;
    }

    this.#hasError = false;
    this.wrapper.classList.remove('has-error');
    this.#errorElement.parentElement.removeChild(this.#errorElement);
  }

  #setNoResults() {
    this.resultsWrapper.innerHTML = '';
    this.bestBetResults.innerHTML = '';
    this.wrapper.classList.add('no-results');
    this.wrapper.classList.remove('no-input', 'only-top-results');
    this.noResults.setAttribute('aria-hidden', 'false');

    if (this.currentQuery) {
      this.currentQuery.hasMoreResults = false;
    }

    this.tracker.refresh();
    this.tracker.track(SearchTracker.actions.NO_RESULTS);
  }

  #setHasInput(hasInput) {
    if (hasInput) {
      this.wrapper.classList.remove('no-input');
      this.noInput.setAttribute('aria-hidden', 'true');
    } else {
      this.resultsWrapper.innerHTML = '';
      this.bestBetResults.innerHTML = '';
      this.wrapper.classList.remove('no-results');
      this.wrapper.classList.add('no-input');
      this.noInput.setAttribute('aria-hidden', 'false');
    }
  }
}

requestIdleCallback(() => {
  document
    .querySelectorAll('.search-crawler-exclude')
    .forEach(el => el.classList.remove('search-crawler-exclude'));
});

new SiteSearch({
  wrapper: '#site-search-wrapper',
  trigger: '.search-trigger',
  close: '#close-search',
  results: '#search-results-wrapper',
  bestBetResults: '#best-bet-results-wrapper',
  noResults: '#search-results-empty',
  noInput: '#search-input-empty',
  announcer: '#search-announcer'
});
