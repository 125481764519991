function getCookie(name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));

  if (match) {
    return match[2];
  }
}

const alertSessionVal = getCookie('alert_session');

document.querySelectorAll('.alert-bar').forEach(alert => {
  const id = alert.dataset.alertid;

  alert.classList.toggle('d-none', id && alertSessionVal === id);

  const close = alert.querySelector('.alert-close-btn');

  if (close) {
    close.addEventListener('click', () => {
      if (id) {
        document.cookie = `alert_session=${id}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
      }

      alert.parentElement.removeChild(alert);

      if (!document.querySelector('.alert-bar')) {
        document
          .querySelectorAll('.has-alert')
          .forEach(el => el.classList.remove('has-alert'));

        const stickyMap = document.querySelector('.locations-map .sticky-map');

        if (stickyMap?.hasAttribute('style')) {
          const headerHeight = document.querySelector('#header')?.offsetHeight ?? 0;
          stickyMap.style.top = `${headerHeight}px`;
        }
      }
    });
  }
});
