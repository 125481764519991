const rootElement = document.documentElement;
const toTop = document.querySelector('#scrollToTop');

function handleScrollToTop() {
  rootElement.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

if (toTop) {
  toTop.addEventListener('click', handleScrollToTop);
}
