import { disableScroll, enableScroll, getScrollbarWidth } from './scrolling';

let mobileMenuOpen = false;
const wrapper = document.querySelector('#main-nav-wrapper');
const topNav = document.querySelector('.main-navigation > .navbar-wrapper');
const header = document.querySelector('.main-navigation .logo');
const menuButton = document.querySelector('.mobile-menu-toggle');
const scrollbarWidth = getScrollbarWidth();

const hasAlert = (() => {
  const main = document.querySelector('#main');
  const checkAlert = () => main.classList.contains('has-alert');

  if (!checkAlert) {
    return () => false;
  } else {
    return checkAlert;
  }
})();

let transitionTimer = null;

function positionHeader(preventAnimation) {
  if (hasAlert()) {
    const { top } = topNav.getBoundingClientRect();
    clearTimeout(transitionTimer);

    if (preventAnimation) {
      header.classList.add('no-transition');
    }

    if (top > 0) {
      header.style.transform = `translateY(${-top}px)`;
    } else {
      header.classList.add('fixed');
    }

    transitionTimer = setTimeout(() => {
      header.classList.remove('no-transition');
    }, 400);
  } else {
    header.style.transform = null;
    header.classList.add('fixed');
  }
}

export function openMobileMenu() {
  if (!wrapper || !menuButton) return;
  if (!mobileMenuOpen) {
    mobileMenuOpen = true;

    menuButton.dispatchEvent(
      new CustomEvent('mobilemenuopen', {
        bubbles: true
      })
    );

    menuButton.setAttribute('aria-expanded', 'true');

    setTimeout(() => {
      requestAnimationFrame(() => {
        document.body.classList.add('mobile-menu-open');
        positionHeader();
        disableScroll();
        wrapper.style.right = null;
      });
    }, 5);
  }
}

export function closeMobileMenu(forceClose) {
  if (!wrapper || !menuButton) return;
  if (mobileMenuOpen || forceClose) {
    mobileMenuOpen = false;

    menuButton.dispatchEvent(
      new CustomEvent('mobilemenuclose', {
        bubbles: true
      })
    );

    enableScroll();

    menuButton.setAttribute('aria-expanded', 'false');
    header.style.transform = null;
    header.classList.remove('fixed');

    if (scrollbarWidth) {
      wrapper.style.right = `${-scrollbarWidth}px`;
    }

    document.body.classList.remove('mobile-menu-open');
    header.style.transform = null;
  }
}

let wrapperTransitionTimer = null;

function toggleMobileMenu() {
  clearTimeout(wrapperTransitionTimer);

  wrapper.classList.add('animating');

  if (mobileMenuOpen) {
    closeMobileMenu();
  } else {
    openMobileMenu();
  }
}

menuButton?.addEventListener('click', toggleMobileMenu);
window.addEventListener('mobilemenubeforeclose', () => closeMobileMenu());

wrapper?.addEventListener('transitionstart', () => {
  clearTimeout(wrapperTransitionTimer);
  wrapper.classList.add('animating');
});

wrapper?.addEventListener('transitionend', () => {
  clearTimeout(wrapperTransitionTimer);

  wrapperTransitionTimer = setTimeout(() => {
    wrapper.classList.remove('animating');
  }, 30);
});

let resizeTimer = null;

function handleResizeComplete() {
  if (mobileMenuOpen) {
    if (window.innerWidth <= 991) {
      positionHeader(true);
    } else {
      closeMobileMenu(true);
    }
  }
}

window?.addEventListener('resize', () => {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(handleResizeComplete, 70);
});
