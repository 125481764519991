document.querySelectorAll('.checkmark-list.two-col').forEach(list => {
  const li = list.querySelector('li');

  if (!li) {
    return;
  }

  let currentOffset = 0;
  const paragraphs = list.querySelectorAll('.text > p');

  const refresh = () => {
    let minLeft = null;
    let maxRight = 0;

    paragraphs.forEach(p => {
      if (minLeft === null) {
        minLeft = li.getBoundingClientRect().left - currentOffset;
      }

      const html = p.innerHTML;
      p.innerHTML = `<span>${html}</span>`;

      const right =
        p.firstElementChild.getBoundingClientRect().right - currentOffset;

      if (right > maxRight) {
        maxRight = right;
      }

      p.innerHTML = html;
    });

    if (minLeft && maxRight) {
      let offset = (window.innerWidth - maxRight - minLeft) / 2;

      if (!isNaN(offset)) {
        offset = Math.round(offset);

        if (offset !== currentOffset) {
          list.style.left = `${offset}px`;
          currentOffset = offset;
        }
      } else {
        list.style.left = null;
      }
    } else {
      list.style.left = null;
    }
  };

  if (window.ResizeObserver) {
    let prevWidth = null;
    let hasInitialized = false;

    const observer = new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;

      if (
        hasInitialized &&
        (!prevWidth || width !== prevWidth) &&
        window.innerWidth >= 767
      ) {
        prevWidth = width;
        refresh();
      }

      if (!hasInitialized) {
        hasInitialized = true;
      }
    });

    observer.observe(list);
    refresh();
    list.classList.remove('hidden');
  } else {
    refresh();
    list.classList.remove('hidden');

    let resizeTimer = null;

    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);

      if (window.innerWidth >= 767) {
        resizeTimer = setTimeout(refresh, 50);
      }
    });
  }
});
