/**
 * Updates the passed dialog to retain focus and restore it when the dialog is closed. Won't
 * upgrade a dialog more than once. Supports IE11+ and is a no-op otherwise.
 * https://gist.github.com/samthor/babe9fad4a65625b301ba482dad284d1
 */
export const registerFocusRestoreDialog = (function () {
  if (!window.WeakMap || !window.MutationObserver) {
    return function () {};
  }
  const registered = new WeakMap();

  let previousFocus = null;
  document.addEventListener(
    'focusout',
    function (ev) {
      previousFocus = ev.target;
    },
    true
  );

  return function registerFocusRestoreDialog(dialog) {
    if (dialog.localName !== 'dialog') {
      console.warn('The element is not a dialog', dialog);
      return;
    }
    if (registered.has(dialog)) {
      return;
    }
    registered.set(dialog, null);

    const realShowModal = dialog.showModal;
    dialog.showModal = function () {
      let savedFocus = document.activeElement;
      if (savedFocus === document || savedFocus === document.body) {
        savedFocus = previousFocus;
      }
      registered.set(dialog, savedFocus);
      realShowModal.call(this);
    };

    dialog.addEventListener('close', function () {
      const savedFocus = registered.get(dialog);
      if (document.contains(savedFocus)) {
        const wasFocus = document.activeElement;
        savedFocus.focus();
        if (document.activeElement !== savedFocus) {
          wasFocus.focus(); // restore focus, we couldn't focus saved
        }
      }
      registered.set(dialog, null);
    });
  };
})();
