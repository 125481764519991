const moduleContainers = document.querySelectorAll('.comparison-module');
const productToggles = document.querySelectorAll('.comparison-module input');

function displayDefault() {
  moduleContainers.forEach(function (moduleContainer) {
    let activeClasses = '';
    const checkedRadioBtns = moduleContainer.querySelectorAll('input:checked');

    checkedRadioBtns.forEach(function (checkedBtn) {
      activeClasses += '.' + checkedBtn.id;
    });

    // show selected
    const productsToShow = moduleContainer.querySelectorAll(activeClasses);
    productsToShow.forEach(function (product) {
      product.classList.remove('d-none');
    });
  });

  document.querySelector('.comparison-disclosure')?.classList.remove('d-none');
}

function handleToggleSelection(e) {
  let activeClasses = '',
    checkedBtnLabels = '';

  const clickedInput = e.currentTarget;
  const currentModuleContainer = clickedInput.closest('.comparison-module');
  const checkedRadioBtns = currentModuleContainer.querySelectorAll(
    'input:checked'
  );
  const productItems = currentModuleContainer.querySelectorAll('.product-item');

  checkedRadioBtns.forEach(function (checkedBtn) {
    activeClasses += '.' + checkedBtn.id;

    // build screen reader alert message
    checkedRadioBtns[checkedRadioBtns.length - 1] === checkedBtn
      ? (checkedBtnLabels += checkedBtn.offsetParent.innerText)
      : (checkedBtnLabels += checkedBtn.offsetParent.innerText + ', ');
  });

  // set screen reader alert message
  const messageEl = currentModuleContainer.querySelector('#visibleProducts');
  messageEl.innerHTML = checkedBtnLabels + ' currently selected product types';

  // hide all
  productItems.forEach(function (product) {
    product.classList.add('d-none');
  });

  // show selected
  const productsToShow = currentModuleContainer.querySelectorAll(activeClasses);
  productsToShow.forEach(function (product) {
    product.classList.remove('d-none');
  });
}

displayDefault();

productToggles.forEach(function (productToggle) {
  productToggle.addEventListener('click', handleToggleSelection);
});
