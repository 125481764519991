import { disableScroll, enableScroll } from './scrolling';
import { createFocusTrap } from 'focus-trap';
import { uniqueId } from 'lodash';
import dialogPolyfill from 'dialog-polyfill';
import { registerFocusRestoreDialog } from './dialog-focus-restore';

const defaultOptions = {
  trigger: null,
  isTriggerLink: false,
  title: null,
  addCloseButton: true
};

const observer = new MutationObserver(([{ target: modal }]) => {
  if (modal.open) disableScroll();
});

const initializedModals = new WeakSet();

export default function initModal(modal, opts = {}) {
  if (typeof modal === 'string') {
    modal = modal.trim();

    let modalEl = null;

    if (modal[0] !== '#' && modal[0] !== '.') {
      modalEl = document.querySelector(`[data-modal="${modal}"]`);
    }

    // If modal not found in data attribute, we query using
    // the string as a selector. We use bitwise right shift as a
    // quick way to confirm the selector doesn't start with a number
    if (!modalEl && !(modal[0] >> 0)) {
      try {
        modalEl = document.querySelector(modal);
      } catch {
        console.warn('Modal not found', modal);
        return;
      }
    }

    modal = modalEl;
  }

  if (!modal) {
    console.warn('Modal not found', modal);
    return;
  }

  if (initializedModals.has(modal)) return;
  initializedModals.add(modal);

  opts = Object.assign({}, defaultOptions, opts);

  const content = modal.querySelector('.fb-modal-content');
  const isDialog = modal.tagName === 'DIALOG';

  if (isDialog) {
    dialogPolyfill.registerDialog(modal);
    registerFocusRestoreDialog(modal);
  }

  // If the modal doesn't contain any focusable elements, we
  // have focus-trap default to focusing the modal wrapper
  if (!isDialog) modal.tabIndex = '-1';

  const focusTrap = isDialog
    ? null
    : createFocusTrap(modal, {
        initialFocus: opts.initialFocus || modal,
        fallbackFocus: modal,
        preventScroll: true
      });

  let isOpen = false;

  const open = e => {
    if (isOpen) return;
    if (opts.isTriggerLink && e) {
      e.preventDefault();
    }
    isOpen = true;
    document.body.classList.add('fb-modal-open');
    modal.classList.add('open');
    disableScroll();

    if (isDialog) {
      modal.showModal?.();
      modal.focus();
    } else {
      focusTrap.activate();
    }

    modal.scrollTop = 0;
    opts.onOpen?.();
  };

  const close = (shouldEnableScroll = true) => {
    if (!isOpen) return;
    isOpen = false;
    modal.classList.remove('open');
    document.body.classList.remove('fb-modal-open');

    if (isDialog) {
      modal.close?.();
    } else {
      focusTrap.deactivate();
      if (shouldEnableScroll) enableScroll();
    }

    opts.onClose?.();
  };

  // One or more elements or selectors can be passed in
  // to automatically set which buttons or elements
  // should open the modal window when clicked
  let { trigger } = opts;

  if (trigger) {
    if (trigger instanceof Element) trigger = [trigger];
    if (typeof trigger === 'string') {
      trigger = document.querySelectorAll(trigger);
    }

    trigger.forEach?.(el => {
      if (typeof el === 'string') {
        el = document.querySelector(el);
      }

      el?.addEventListener('click', open);
    });
  }

  // Triggers can also be set by using
  // an HTML attribute: "data-open-modal"
  const { modal: modalId, name: modalName } = modal.dataset;
  const selectors = [];

  if (modalId) selectors.push(`[data-open-modal="${modalId}" i]`);
  if (modalName) selectors.push(`[data-open-modal="${modalName}" i]`);

  if (selectors.length) {
    document.addEventListener('click', e => {
      const closestEl = e.target.closest(selectors.join(','));
      if (closestEl) {
        opts.isTriggerLink = closestEl.tagName === 'A';
        open(e);
      }
    });
  }

  // Add title bar
  let titleEl =
    modal.querySelector('.fb-modal-title') ?? modal.querySelector('.fb-modal-top');

  if (!titleEl && opts.title) {
    titleEl = document.createElement('h2');
    titleEl.className = 'fb-modal-title';
    titleEl.innerText = decodeURIComponent(opts.title);

    const parent = content ?? modal;
    parent.prepend(titleEl);
  }

  if (titleEl && !titleEl.textContent.trim()) {
    titleEl.remove();
    titleEl = null;
  }

  if (
    titleEl &&
    !modal.hasAttribute('aria-labelledby') &&
    !modal.hasAttribute('aria-label')
  ) {
    if (!(titleEl instanceof HTMLHeadingElement)) {
      titleEl = titleEl.querySelector('h1, h2, h3, h4') ?? titleEl;
    }

    titleEl.id ||= uniqueId('modal-title-');
    modal.setAttribute('aria-labelledby', titleEl.id);
  }

  // Close modal when close button is clicked
  if (!modal.querySelector('.close-button') && opts.addCloseButton) {
    const closeButton = document.createElement('button');
    closeButton.className = 'close-button';
    closeButton.innerHTML = '<span class="sr-only">Close</span>';

    const parent = content ?? modal;
    parent.prepend(closeButton);
  }

  const closeTriggers = modal.querySelectorAll('.close-button');

  closeTriggers.forEach(button => {
    button.addEventListener('click', close);
  });

  if (!closeTriggers.length) {
    modal.classList.add('no-close-button');
  }

  // Close modal when user clicks outside the content area
  let mouseDownTarget = null;

  content?.addEventListener('click', e => e.stopPropagation());

  modal.addEventListener('mousedown', e => {
    if (window.innerWidth <= 500) return;
    mouseDownTarget = e.target;
  });

  modal.addEventListener('click', () => {
    if (mouseDownTarget === modal) close();
    mouseDownTarget = null;
  });

  // Close modal when escape key is pressed
  window.addEventListener('keydown', e => {
    const isOpen = modal.open || modal.classList.contains('open');
    const isEscape = e.key === 'Esc' || e.key === 'Escape';
    if (isOpen && isEscape) close();
  });

  if (isDialog) {
    // There is no native 'open' event on dialog elements so
    // we listen for changes to the dialog's open attribute
    observer.observe(modal, { attributes: true, attributeFilter: ['open'] });
  } else {
    modal.setAttribute('aria-modal', true);

    if (!modal.hasAttribute('role')) {
      modal.setAttribute('role', 'dialog');
    }
  }

  // Reset state when native dialog close event fires
  modal.addEventListener('close', () => {
    mouseDownTarget = null;
    enableScroll();
  });

  return { open, close };
}

// Modals can be initialized with HTML attribute: "data-modal"
document.querySelectorAll('[data-modal]').forEach(initModal);
