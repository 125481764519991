function createScreenReaderText(el, text) {
  if (typeof el === 'string') {
    el = document.querySelector(el);
  }

  if (!el) {
    return;
  }

  const sr = document.createElement('span');
  sr.className = 'sr-only';
  sr.innerText = text;

  el.appendChild(sr);
}

function replaceTags(selector, tagName) {
  if (!Element.prototype.getAttributeNames) {
    return;
  }

  document.querySelectorAll(selector).forEach(function (el) {
    var newEl = document.createElement(tagName);

    el.getAttributeNames().forEach(function (attr) {
      newEl.setAttribute(attr, el.getAttribute(attr));
    });

    newEl.innerHTML = el.innerHTML;

    el.parentElement.replaceChild(newEl, el);
  });
}

let counter = 0;

function transformAriaLabels(selector, searchEntireDocument) {
  selector = [selector + '[aria-labelledby]', selector + '[aria-describedby]'].join(', ');

  document.querySelectorAll(selector).forEach(function (el) {
    var searchEl = searchEntireDocument ? document : el;
    var labelId = el.getAttribute('aria-labelledby');
    var descriptionId = el.getAttribute('aria-describedby');
    var hasLabel = false;
    var hasDescription = false;

    if (descriptionId && descriptionId === labelId) {
      descriptionId = null;
      el.removeAttribute('aria-describedby');
    }

    if (labelId) {
      var label = searchEl.querySelector('#' + labelId);

      if (label) {
        counter++;
        hasLabel = true;
        labelId += '-fb-' + counter;

        label.id = labelId;
        el.setAttribute('aria-labelledby', labelId);
      } else {
        el.removeAttribute('aria-labelledby');
      }
    }

    if (descriptionId) {
      var description = searchEl.querySelector('#' + descriptionId);

      if (description) {
        counter++;
        hasDescription = true;
        descriptionId += '-fb-' + counter;

        description.id = descriptionId;
        el.setAttribute('aria-describedby', descriptionId);
      } else {
        el.removeAttribute('aria-describedby');
      }
    }

    if (!hasLabel && !hasDescription) {
      el.setAttribute('aria-hidden', 'true');
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  if (window.kentico?.pageBuilder) {
    return;
  }

  // Empty elements can also cause layout shift,
  // so we prioritize transforming these first
  const emptySelectors = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    '.interior-split-banner',
    '.description > p',
    '.section-top p',
    '.cta-banner',
    '.media-body p',
    '.card-body p'
  ];

  document.querySelectorAll(emptySelectors.join(', ')).forEach(el => {
    if (!el.textContent.trim() && !el.children.length) {
      el.parentElement.removeChild(el);
    }
  });

  // Defer additional accessibility transformations
  // until the rest of the page has finished rendering
  window.requestIdleCallback(() => {
    // Images with no alt tags
    document
      .querySelectorAll('img:not([alt])')
      .forEach(image => image.setAttribute('alt', ''));

    // Capitalized text in paragraph tags
    replaceTags('p.lead.section-header', 'span');

    // Ensure unique IDs for icon labels/descriptions
    transformAriaLabels('.svg-icon');
  });

  // Add screen reader text
  createScreenReaderText(
    '.wealth-hero-links a[href*="fbinvestmentservices.com"]',
    'FB Investment Services - opens in a new tab'
  );
  createScreenReaderText(
    '.wealth-hero-links a[href*="brokercheck.finra.org"]',
    'BrokerCheck, check the background of this investment professional - opens in a new tab.'
  );

  // Add attributes to Google's recaptcha
  const form = document.querySelector('.form-widget-form');

  if (form) {
    const observer = new MutationObserver(() => {
      setTimeout(() => {
        document.querySelectorAll('.g-recaptcha-response').forEach(item => {
          item.setAttribute('aria-hidden', 'true');
          item.setAttribute('aria-label', 'Do not use');
          item.setAttribute('aria-readonly', 'true');
        });
      }, 200);
    });

    observer.observe(form, { childList: true, subtree: true });
  }
});

// Prefers reduced motion
export const reducedMotionMediaQuery = window.matchMedia(
  '(prefers-reduced-motion: reduce)'
);

let shouldReduceMotion = reducedMotionMediaQuery.matches;

if (reducedMotionMediaQuery?.addEventListener) {
  reducedMotionMediaQuery.addEventListener('change', () => {
    shouldReduceMotion = reducedMotionMediaQuery.matches;
  });
} else if (reducedMotionMediaQuery?.addListener) {
  reducedMotionMediaQuery.addListener(() => {
    shouldReduceMotion = reducedMotionMediaQuery.matches;
  });
}

export function prefersReducedMotion() {
  return shouldReduceMotion;
}

// Disable requestAnimationFrame when
// the user prefers reduced motion
export function requestFrame(callback) {
  if (!callback) {
    return;
  }

  if (shouldReduceMotion) {
    setTimeout(callback, 0);
  } else {
    requestAnimationFrame(callback, 0);
  }
}
