const sections = Array.from(
  document.querySelectorAll('main > div, main > section')
).filter(
  el =>
    !el.classList.contains('ignore-alt-bg') &&
    !el.classList.contains('component-placeholder')
);

function shouldSetBackgrounds() {
  if (document.body.classList.contains('no-alt-bg')) return false;

  return document.body.classList.contains('landing-page')
    ? document.body.classList.contains('alt-section-bg')
    : !document.querySelector('div[class*="blog-"], section[class*="blog-"]');
}

function setAlternateGrayBackground() {
  let isWhite = false;

  sections.forEach(component => {
    isWhite = !isWhite;

    if (component.classList.contains('continue-alt-bg')) {
      isWhite = false;
      return;
    }

    if (component.classList.contains('bg-white')) {
      isWhite = true;
    } else if (component.classList.contains('bg-gray-100')) {
      isWhite = false;
    }

    const className = isWhite ? 'bg-white' : 'bg-gray-100';
    component.classList.add(className);
  });
}

if (shouldSetBackgrounds()) {
  setAlternateGrayBackground();
}

document.addEventListener('DOMContentLoaded', () => {
  window.requestIdleCallback(() => {
    document.body.classList.remove('initializing');
  });
});

if (document.body.classList.contains('KenticoAdmin')) {
  document.body.classList.remove('initializing');
}
