const featureBoxes = document.querySelectorAll('.full-width-feature-highlights-content-boxes');

featureBoxes.forEach(function (feature) {
  const boxColumn = feature.querySelector('.box-columns');
  const boxColumnCards = feature.querySelectorAll('.box-columns .card').length;
  const remainder = boxColumnCards % 2;

  if (boxColumnCards > 3 && remainder === 0) {
    boxColumn.classList.add('even');
  } else if (boxColumnCards > 3 && remainder === 1) {
    boxColumn.classList.add('odd');
  }
});
