const scrollbarWidth = getScrollbarWidth();
const header = document.querySelector('#header, .header-section');
const hasStickyHeader =
  header?.id === 'header' || header?.classList.contains('fixed-header');

let scrollPosition = 0;

export function getScrollbarWidth() {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export function disableScroll() {
  if (document.documentElement.classList.contains('disable-scroll')) {
    return;
  }

  scrollPosition = window.pageYOffset;
  document.documentElement.classList.add('disable-scroll');
  document.body.classList.add('disable-scroll');
  document.body.style.top = `-${scrollPosition}px`;

  if (scrollbarWidth) {
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    if (header && hasStickyHeader) {
      header.style.right = `${scrollbarWidth}px`;
    }
  }
}

export function enableScroll() {
  document.body.classList.remove('disable-scroll');
  document.documentElement.classList.remove('disable-scroll');
  document.body.style.top = null;
  document.body.style.paddingRight = null;
  document.body.style.height = null;

  if (header) {
    header.style.right = null;
  }

  window.scrollTo({ top: scrollPosition, behavior: 'instant' });
  scrollPosition = 0;
}
