// Load more articles
document.querySelectorAll('.sc-blog-listing').forEach(wrapper => {
  const listing = wrapper.querySelector('.sc-blog-listing-cards');
  const loadMoreWrapper = wrapper.querySelector('.load-more-wrapper');
  const loadMoreButton = wrapper.querySelector('.sc-load-more');

  if (!listing || !loadMoreButton) return;

  const endpoint = '/apis/securitycenterapi/getarticlecards';
  const loadMoreText = loadMoreButton.innerText;

  const category = wrapper.dataset.category;
  wrapper.removeAttribute('data-category');

  let currentPage = 0;
  let isLoading = false;
  let errorMessage = null;

  async function fetchPosts() {
    if (isLoading) return;

    isLoading = true;

    const url = new URL(endpoint, window.location.origin);
    url.searchParams.set('page', currentPage);

    if (category) {
      url.searchParams.set('category', category);
    } else {
      url.searchParams.set('path', window.location.pathname);
    }

    loadMoreButton.disabled = true;
    loadMoreButton.innerText = 'Loading...';

    try {
      const res = await fetch(url);
      const data = (await res.json()) ?? {};

      currentPage++;

      refreshPostListing(data);

      isLoading = false;
    } catch (error) {
      console.error('Failed to fetch posts', error);

      if (!errorMessage) {
        errorMessage = document.createElement('div');
        errorMessage.className = 'message message-error sc-load-more-error';
        errorMessage.innerText = 'Something went wrong, please try again later';
        loadMoreButton.before(errorMessage);
      }

      loadMoreButton.disabled = false;
      loadMoreButton.innerText = loadMoreText;

      isLoading = false;
    }
  }

  loadMoreButton.addEventListener('click', fetchPosts);

  function refreshPostListing(data) {
    const { posts, areMorePostsAvailable } = data;

    if (!areMorePostsAvailable || !posts.length) {
      if (loadMoreWrapper) {
        loadMoreWrapper.remove();
      } else {
        loadMoreButton.remove();
      }
    }

    const fragment = document.createDocumentFragment();

    posts.forEach(post => {
      const card = createArticleCard(post);
      if (card) fragment.append(card);
    });

    listing.append(fragment);

    errorMessage?.remove();

    if (areMorePostsAvailable) {
      loadMoreButton.disabled = false;
      loadMoreButton.innerText = loadMoreText;
    }
  }

  function createArticleCard({ url, headline, teaser, image }) {
    if (!url || !headline) return null;

    const wrapper = document.createElement('a');
    wrapper.className = 'tile';
    wrapper.href = url;

    if (image) {
      const imgWrapper = document.createElement('div');
      imgWrapper.className = 'tile-image';

      const img = document.createElement('img');
      img.src = image;
      img.alt = '';

      imgWrapper.append(img);
      wrapper.append(imgWrapper);
    }

    const content = document.createElement('div');
    content.className = 'tile-content';

    const top = document.createElement('div');
    top.className = 'tile-top';

    const title = document.createElement('h3');
    title.innerText = headline;

    top.append(title);
    content.append(top);

    if (teaser) {
      const text = document.createElement('div');
      text.className = 'tile-text';

      // Strip out any existing HTML and add paragraphs
      teaser = teaser
        .trim()
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<[^>]*>/g, '')
        .trim()
        .split('\n')
        .map(p => `<p>${p.trim()}</p>`)
        .join('');

      text.innerHTML = teaser;

      content.append(text);
    }

    wrapper.append(content);

    return wrapper;
  }
});
