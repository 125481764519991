import tracker from './analytics';
import { getUrlParameter, upperFirst } from './helpers';
import { initIframeModal } from './modal-iframe';
import device from './browser-detection';
import addInternetExplorerBanner from './add-ie-banner';

let bannerAdded = false;

function initBookingOptions(button) {
  const bookingAttributeUrl = button.dataset.bookingModal;
  const iframeDelay = button.dataset.bookingDelay;

  let url = bookingAttributeUrl;
  let title = button.dataset.bookingTitle?.trim() ?? 'Schedule an appointment';
  let isAgentSpecificPage = false;
  let agentName = null;

  // Allow overwriting booking data
  // attributes with URL parameters
  const params = { page: getUrlParameter('booking') };

  if (params.page) {
    isAgentSpecificPage = true;
    params.firstName = getUrlParameter('firstname');
    params.lastName = getUrlParameter('lastname');

    if (params.page.startsWith('http')) {
      url = params.page;
    } else {
      // Attempt to retrieve the correct part of the
      // URL if the URL isn't formatted correctly
      if (params.page.includes('/')) {
        params.page = params.page.replace(
          /(.*calendar\/|^\/)?([^/]+)(\/bookings.*\/?)?/,
          '$2'
        );

        if (params.page.includes('/')) {
          const parts = params.page.split('/');
          params.page = parts.find(s => s.includes('@')) ?? url;
        }
      }

      // Booking page URLs contain an @ sign and
      // a domain. If one is not present in the
      // URL we default to fremontbank.com
      if (!params.page.includes('@')) {
        params.page += '@fremontbank.com';
      }

      url = `https://outlook.office365.com/owa/calendar/${params.page}/bookings/`;

      // Replace modal title with the agent's name
      if (params.firstName) {
        params.firstName = upperFirst(params.firstName);
        params.lastName = upperFirst(params.lastName);

        agentName = decodeURIComponent(`${params.firstName} ${params.lastName}`).trim();

        if (agentName) {
          title = `Schedule an appointment with ${agentName}`;
        }
      }
    }
  }

  if (device.browser.ie && !bannerAdded) {
    addInternetExplorerBanner();
    bannerAdded = true;
  }

  if (url) {
    url = url.replace(/http:/g, 'https:');
  }

  if (title) {
    title = decodeURIComponent(title);
  }

  button.removeAttribute('data-booking-delay');
  button.removeAttribute('data-booking-modal');
  button.removeAttribute('data-booking-title');

  function onOpen() {
    tracker.track('booking_open', {
      booking_page: url,
      is_agent: isAgentSpecificPage,
      agent_name: isAgentSpecificPage ? agentName ?? 'Unknown Agent' : undefined
    });
  }

  return { title, url, iframeDelay, onOpen };
}

function init() {
  requestIdleCallback(() => {
    document.querySelectorAll('[data-booking-modal]').forEach(el => {
      const modalOptions = initBookingOptions(el);
      initIframeModal(el, modalOptions);
    });
  });
}

if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
