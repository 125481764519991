import { scrollToContainer, getUrlParameter } from './helpers';

let faqParamVal, topicParamVal, subTopicParamVal;

const accordionFAQ = document.querySelector('#accordionFAQ');
const accordionFAQCards = accordionFAQ ? accordionFAQ.querySelectorAll('.card') : '';

const browseFAQAccordion = document.querySelector('#accordionBrowseFAQ');
const browseFAQCards = browseFAQAccordion
  ? browseFAQAccordion.querySelectorAll('.card')
  : '';

const browseByTopicNavAccordion = document.querySelector('#accordionBrowseByTopicNav');
const browseBySubTopicNavBtns = browseByTopicNavAccordion
  ? browseByTopicNavAccordion.querySelectorAll('button.sub-topic')
  : '';

function expandFaqItem(param, shouldScroll = true) {
  const faqCollapseBtn = document.querySelector('button[id="btn-' + param + '-faq"]');
  faqCollapseBtn ? faqCollapseBtn.click() : '';

  const expandedFaqId = '#id-' + param + '-faq';

  if (shouldScroll) {
    scrollToContainer(expandedFaqId);
  }
}

function expandFirstFaq() {
  if (accordionFAQCards) {
    accordionFAQCards[0].querySelector('button').classList.remove('collapsed');
    accordionFAQCards[0].querySelector('button').setAttribute('aria-expanded', 'true');
    accordionFAQCards[0].querySelector('.collapse').classList.add('show');
  }
}

function expandFirstVisibleFaq() {
  const visibleCards = [...browseFAQCards].filter(
    card => !card.classList.contains('d-none')
  );

  visibleCards.forEach(function (card, index) {
    if (index === 0) {
      card.querySelector('button').classList.remove('collapsed');
      card.querySelector('button').setAttribute('aria-expanded', 'true');
      card.querySelector('.collapse').classList.add('show');
    }
  });
}

function collapseAllFaqs() {
  browseFAQCards.forEach(function (card) {
    card.querySelector('button').classList.add('collapsed');
    card.querySelector('button').setAttribute('aria-expanded', 'false');
    card.querySelector('.collapse').classList.remove('show');
  });
}

function expandNavTopic(topicParam) {
  const topicBtn = document.querySelector(
    `.browse-by-topic-nav-item button[data-topic="${topicParam}"]`
  );

  const topicCollapse = document.querySelector(
    `.browse-by-topic-nav-item .collapse[data-topic="${topicParam}"]`
  );

  if (!topicBtn || !topicCollapse) {
    return;
  }

  topicBtn.classList.remove('collapsed');
  topicBtn.setAttribute('aria-expanded', 'true');
  topicCollapse.classList.add('show');
}

function hideAllBrowseFaqs(faqs) {
  faqs.forEach(function (faq) {
    faq.classList.add('d-none');
  });
}

function showAllBrowseFAQsByTopic() {
  if (browseFAQAccordion) {
    const faqs = browseFAQAccordion.querySelectorAll('.card');
    faqs.forEach(function (faq) {
      faq.classList.remove('d-none');
    });
  }
}

function filterBrowseFAQsByTopic(topic) {
  if (browseFAQAccordion) {
    hideAllBrowseFaqs(browseFAQCards);

    browseFAQCards.forEach(function (faq) {
      const faqTopic = faq.getAttribute('data-topic');

      if (faqTopic === topic) {
        faq.classList.remove('d-none');
      }
    });
  }
}

function filterBrowseFAQsBySubTopic(topic, subtopic) {
  if (browseFAQAccordion) {
    hideAllBrowseFaqs(browseFAQCards);

    browseFAQCards.forEach(function (faq) {
      const faqTopic = faq.getAttribute('data-topic');
      const faqSubTopic = faq.getAttribute('data-subtopic');

      if (faqTopic === topic && faqSubTopic === subtopic) {
        faq.classList.remove('d-none');
      }
    });

    // expandFirstVisibleFaq();
  }
}

function topicNavFaqDisplay(topic, subTopic, shouldScroll = true) {
  // scrollToContainer('#browseByTopic');
  if (shouldScroll) {
    scrollToContainer('#faq-topic-list');
  }

  collapseAllFaqs();

  if (subTopic) {
    filterBrowseFAQsBySubTopic(topic, subTopic);
  } else if (topic) {
    filterBrowseFAQsByTopic(topic);
  }

  expandFirstVisibleFaq();
  expandNavTopic(topic);
}

function handleSubTopicNav(e) {
  const currentSubTopic = e.currentTarget;
  const subTopicParent = currentSubTopic.getAttribute('data-topic');
  const subTopic = currentSubTopic.getAttribute('data-subtopic');

  if (subTopicParent !== '' && subTopic !== '') {
    filterBrowseFAQsBySubTopic(subTopicParent, subTopic);
  }

  expandFirstVisibleFaq();
}

window.addEventListener('load', function () {
  faqParamVal = getUrlParameter('faq');
  topicParamVal = getUrlParameter('topic');
  subTopicParamVal = getUrlParameter('subtopic');

  // urlHash !== '' ? scrollToContainer(urlHash) : '';

  faqParamVal !== undefined ? expandFaqItem(faqParamVal) : '';

  topicParamVal !== undefined
    ? topicNavFaqDisplay(topicParamVal, subTopicParamVal, false)
    : '';

  // no params, expand first faq item
  window.location.href.indexOf('?') == -1 ? expandFirstFaq() : '';
});

if (browseBySubTopicNavBtns) {
  browseBySubTopicNavBtns.forEach(function (subTopicBtn) {
    subTopicBtn.addEventListener('click', handleSubTopicNav);
  });
}

$(browseByTopicNavAccordion).on('shown.bs.collapse', () => {
  const expandedItem = browseByTopicNavAccordion.querySelector(
    'button[aria-expanded="true"]'
  );
  const navTopic = expandedItem.getAttribute('data-topic');
  expandedItem ? filterBrowseFAQsByTopic(navTopic) : '';
  expandFirstVisibleFaq();
});

$(browseByTopicNavAccordion).on('hidden.bs.collapse', () => {
  collapseAllFaqs();

  // show all browse faq topics
  const expandedItem = browseByTopicNavAccordion.querySelector(
    'button[aria-expanded="true"]'
  );
  expandedItem === null ? showAllBrowseFAQsByTopic() : '';
});
