document.querySelectorAll('.toggle-wrapper').forEach(wrapper => {
  const buttons = wrapper.querySelectorAll('.toggle-button');
  const toggleBg = wrapper.querySelector('.toggle-bg');
  const targets = [];

  if (!buttons.length) return;

  let value = undefined;

  Object.defineProperty(wrapper, 'value', {
    set: v => {
      const button = Array.from(buttons).find(b => b.value === v);
      button?.click();
      value = button?.value;
    },
    get: () => value
  });

  buttons.forEach(button => {
    const targetId = button.dataset.target;
    let target = null;

    if (targetId) {
      target = document.getElementById(button.dataset.target);

      if (target) {
        targets.push(target);
      } else {
        console.warn(`Toggle element not found: ${targetId ?? 'No target'} `);
      }
    }

    if (button.classList.contains('active')) {
      value = button.value;
      button.tabIndex = -1;
      if (target) target.classList.add('active');
    }

    button.addEventListener('click', () => {
      value = button.value;
      button.classList.add('active');
      button.setAttribute('aria-pressed', 'true');
      button.tabIndex = -1;

      const inactiveButton = button === buttons[0] ? buttons[1] : buttons[0];
      inactiveButton.classList.remove('active');
      inactiveButton.setAttribute('aria-pressed', 'false');
      inactiveButton.tabIndex = 0;

      toggleBg?.classList.toggle('right', button === buttons[1]);

      if (target) {
        targets.forEach(el => el.classList.toggle('active', el.id === targetId));
      }

      wrapper.dispatchEvent(
        new Event('change', {
          bubbles: true
        })
      );
    });
  });
});
