const blogItems = document.querySelector('#blog-items');
const loadMorePostsBtn = document.querySelector('#loadMorePosts');
const browseByOptions = document.querySelectorAll('.browse-by-category input');

function handleOptionSelection(e) {
  const option = e.currentTarget;
  const optionId = option.id;

  blogItems.innerHTML = '';
  loadMorePostsBtn.classList.remove('d-none');
  loadAjaxBlogs(0, optionId);

  // var blogListCards = document.querySelectorAll('.blog-list-grid .card');
  var blogListCards = document.querySelectorAll('.blog-list-grid .tile');

  blogListCards.forEach(function (card) {
    if (optionId === 'all-articles') {
      // reset
      card.classList.remove('d-none');
    } else if (card.classList.contains(optionId)) {
      card.classList.remove('d-none');
    } else {
      card.classList.add('d-none');
    }
  });
}

function handleLoadMoreBtn() {
  loadAjaxBlogs(window.pageNumber + 1);
}

function defaultAllArticles() {
  browseByOptions[0].setAttribute('checked', '');
  browseByOptions[0].parentElement.classList.add('active');
}

function generateBlogItem(
  categoryId = [],
  postTitle,
  postBody,
  postUrl,
  imageUrl,
  imageAltText
) {
  // Set up post tile
  const tag = postUrl ? 'a' : 'div';
  const post = document.createElement(tag);
  post.className = 'tile';

  if (postUrl) {
    post.href = postUrl;
  }

  if (Array.isArray(categoryId)) {
    categoryId.forEach(cat => post.classList.add(cat));
  }

  // Add thumbnail
  imageUrl = imageUrl?.trim();

  if (imageUrl) {
    const img = document.createElement('div');
    img.className = 'tile-image';
    img.innerHTML = `
      <img src="${imageUrl}" alt="${imageAltText}" loading="lazy" decoding="async" />
    `.trim();

    post.appendChild(img);
  }

  const content = document.createElement('div');
  content.className = 'tile-content';
  content.innerHTML = `
    <div class="tile-top">
      <h3>${postTitle}</h3>
    </div>
    <div class="tile-text">
      <p>${postBody}</p>
    </div>
  `;

  post.appendChild(content);

  return post;
}

function ajaxSuccess(data) {
  var anyCards = false;
  var blogs = data.Blogs;
  for (var i in blogs) {
    anyCards = true;
    var item = blogs[i];
    var post = generateBlogItem(
      item.CategoryId,
      item.PostTitle,
      item.BodyTeaser,
      item.NodeAliasPath,
      item.Image,
      item.ImageAltText,
      ''
    );

    blogItems.appendChild(post);
  }

  if (anyCards === false || data.IsMore === false) {
    loadMorePostsBtn.classList.add('d-none');
  }

  blogItems.dataset.count = blogItems.childElementCount;
}

function loadAjaxBlogs(pageNumber, optionId) {
  window.pageNumber = pageNumber;

  if (typeof optionId !== 'undefined') window.categoryId = optionId;
  const url = `/apis/BlogListingApi/Blogs/${pageNumber}?cat=${window.categoryId}&count=${window.resultsCount}`;
  $.ajax({
    url,
    dataType: 'json'
  })
    .done(ajaxSuccess)
    .fail(function () {});
}

function load() {
  window.resultsCount = 8;

  if (window.innerWidth >= 992) {
    window.resultsCount = 8;
  } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
    window.resultsCount = 4;
  } else if (window.innerWidth < 768) {
    window.resultsCount = 4;
  }

  blogItems.innerHTML = '';
  loadAjaxBlogs(0, '');

  if (loadMorePostsBtn !== null) {
    loadMorePostsBtn.addEventListener('click', handleLoadMoreBtn);
  }

  if (browseByOptions.length > 0) {
    // browse by category filter
    browseByOptions.forEach(function (option) {
      option.addEventListener('click', handleOptionSelection);
    });

    defaultAllArticles();
  }
}

window.resultsCount = 8;
window.addEventListener('load', () => {
  if (blogItems) {
    load();
  }
});
