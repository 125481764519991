
const fwCtaBannerContainer = document.querySelector('.component-full-width-cta-banner');
const intFwBannerContainer = document.querySelector('.interior-full-width-banner');

function invisibleDuplicateContent(rows) {
  rows.forEach(function (CtaRow) {
    const fwCtaBannerColums = CtaRow.querySelectorAll('div[class^="col"]');
    const firstColumn = fwCtaBannerColums[0];
    const secondColumn = fwCtaBannerColums[1];

    const ctaContent = firstColumn.innerHTML;

    // (╯°□°）╯︵ ┻━┻
    // hack to address height of CTA container in mobile
    secondColumn.classList.add('invisible');  // hide, remove from accessibility tree
    secondColumn.classList.add('py-5');
    secondColumn.insertAdjacentHTML('afterbegin', ctaContent)
  });
}

if (fwCtaBannerContainer) {
  const fwCtaBannerContainerRows = document.querySelectorAll('.component-full-width-cta-banner .row');
  invisibleDuplicateContent(fwCtaBannerContainerRows);
}

if (intFwBannerContainer) {
  const intFwBannerContainerRows = document.querySelectorAll('.interior-full-width-banner .row');
  invisibleDuplicateContent(intFwBannerContainerRows);
}
