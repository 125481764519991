// Prevent empty content areas from
// creating inconsistent whitespace
const isPageBuilder =
  !!window.kentico?.pageBuilder ||
  document.body.classList.contains('KenticoAdminPageBuilder');

const selectors = [
  '.section-content',
  '.section-bottom',
  '.content-section > .content-wrapper'
].join(',');

const ignoreSelectors = [
  '.show-empty-content',
  '.mkto-form-wrapper',
  'form',
  'img',
  'video',
  'iframe',
  'audio',
  'svg',
  'script',
  'style'
].join(',');

if (!isPageBuilder) {
  document
    .querySelectorAll('.hidden-content-section')
    .forEach(el => el.parentElement.removeChild(el));
}

document.querySelectorAll(selectors).forEach(el => {
  const text = el.textContent.trim();
  if (text) return;

  // Don't remove empty element if it contains elements that
  // still need to be initialized, aren't expected to contain
  // text, or if section property specifically allows empty content
  if (el.querySelector(ignoreSelectors) || el.closest('.show-empty-content')) {
    return;
  }

  // Multicolumn sections with empty body content
  if (!text && !isPageBuilder && el.querySelector('.content-row')) {
    el.parentElement.removeChild(el);
    return;
  }

  // Other elements with empty content
  if (
    !text &&
    !isPageBuilder &&
    !el.classList.contains('has-page-builder') &&
    el.childElementCount < 2 &&
    !el.firstElementChild?.childElementCount
  ) {
    el.parentElement.removeChild(el);
  }
});

// Adds CSS class to body while
// the window is being resized
let windowResizeTimer = null;
let isResizing = false;

function handleResizeComplete() {
  isResizing = false;
  document.body.classList.remove('resizing');
}

window.addEventListener('resize', () => {
  clearTimeout(windowResizeTimer);

  if (!isResizing) {
    isResizing = true;
    document.body.classList.add('resizing');
  }

  windowResizeTimer = setTimeout(handleResizeComplete, 70);
});
