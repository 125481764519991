function handleToggleClick (e) {
  const currentToggleBtn = e.currentTarget;
  const prevSibling = currentToggleBtn.previousElementSibling;

  const prevSiblingIsActive = prevSibling.classList.contains('active');
  const toggleExpanded = currentToggleBtn.getAttribute('aria-expanded');

  if (prevSiblingIsActive) {
    prevSibling.classList.remove('active');
  } else {
    prevSibling.classList.add('active');
  }

  // accessibility: set state of dropdown section
  toggleExpanded === 'true'
    ? currentToggleBtn.setAttribute('aria-expanded', 'false')
    : currentToggleBtn.setAttribute('aria-expanded', 'true');
}

// get all toggles
const dropdownToggles = document.querySelectorAll('.topic-toggle');
dropdownToggles.forEach(function (button) {
  button.addEventListener('click', handleToggleClick);
});
