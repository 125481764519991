import { throttle } from './helpers';

const canvas = document.querySelector('#footer-canvas');

function init() {
  if (!canvas) return;

  const img = new Image();
  let imgLoaded = false;

  img.onload = () => {
    imgLoaded = true;
    drawCanvas();
  };

  img.src = '/Content/breakout-fremont-bank/dist/images/fremont-map.svg';

  function getImgSize(canvasSize) {
    let width = canvasSize.width;
    let height = (width * img.height) / img.width;

    if (height < canvasSize.height) {
      const newWidth = (canvasSize.height * img.width) / img.height;

      if (newWidth >= canvasSize.width) {
        width = newWidth;
        height = canvasSize.height;
      } else {
        height = canvasSize.height * 1.5;
        width = (height * img.width) / img.height;
      }
    }

    return { width, height };
  }

  function drawCanvas() {
    if (!imgLoaded) return;

    const dpr = window.devicePixelRatio || 1;
    const ctx = canvas.getContext('2d');
    const width = canvas.parentElement.offsetWidth;
    const height = canvas.parentElement.offsetHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    canvas.width = width * dpr;
    canvas.height = height * dpr;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    const size = getImgSize({ width, height });
    ctx.drawImage(img, 0, 0, size.width * dpr, size.height * dpr);
  }

  drawCanvas();

  const throttledDrawCanvas = throttle(drawCanvas);
  let drawTimer = null;
  let isInView = false;

  if (window.IntersectionObserver) {
    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      isInView = isIntersecting;
    });

    observer.observe(canvas);
  }

  window.addEventListener('resize', () => {
    clearTimeout(drawTimer);
    if (isInView) throttledDrawCanvas();
    drawTimer = setTimeout(drawCanvas, 100);
  });
}

if (document.readyState === 'complete') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
