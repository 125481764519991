import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import $ from 'jquery';

const galleries = document.querySelectorAll('.light-gallery-wrapper');
if (galleries) {
  galleries.forEach(gallery => {
    if (gallery.dataset.enabled.toLowerCase() == 'true') {
      initGallery(gallery);
    } else {
      window.addEventListener('DOMContentLoaded', () => {
        unwrapGalleryItems(gallery);
      });
    }
  });
}

function initGallery(galleryEl) {
  lightGallery(galleryEl, {
    plugins: [lgZoom, lgThumbnail, lgVideo],
    selector: '.gallery-item',
    licenseKey: 'b72a17f2-0ba4-4550-800d-9907373e2018',
    enableDrag: false,
    getCaptionFromTitleOrAlt: false,
    speed: 500
  });
}

function unwrapGalleryItems(gallery) {
  const items = gallery.querySelectorAll('.gallery-item');
  items.forEach(item => {
    const newWrapper = document.createElement('div');
    newWrapper.classList.add('gallery-item');
    $(item).wrap(newWrapper).contents().unwrap();
  });
}
