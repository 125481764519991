const device = {
  os: {
    windows: /^Win/i.test(navigator.platform),
    mac: /^Mac/i.test(navigator.platform),
    ios:
      /iPhone|iPad|iPod/i.test(navigator.platform) ||
      (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 0),
    android: /Android/i.test(navigator.userAgent) || /webOS/i.test(navigator.userAgent)
  },

  browser: {
    chrome: !!window.chrome && !/\bEdg/i.test(navigator.userAgent),
    edge: /\bEdg/i.test(navigator.userAgent),
    firefox: /Firefox/i.test(navigator.userAgent),
    safari: !window.chrome && /Safari/i.test(navigator.userAgent),
    ie: !!window.document.documentMode
  },

  get isMobile() {
    return this.os.ios || this.os.android;
  },

  get isDesktop() {
    return !this.isMobile;
  }
};

const operatingSystems = Object.keys(device.os);
const browsers = Object.keys(device.browser);
const setDevice = (name, value) => {
  document.body.classList.add(value);
  document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/;`;
};

for (let key of operatingSystems) {
  if (device.os[key]) {
    setDevice('device_os', key);
    break;
  }
}

for (let key of browsers) {
  if (device.browser[key]) {
    setDevice('device_browser', key);
    break;
  }
}

export default device;

if (device.os.ios || device.os.android) {
  document.body.classList.add('is-mobile');
  const selector = device.os.android ? 'a.ios, a.macintosh' : 'a.android';

  document.querySelectorAll(selector).forEach(link => {
    link.parentElement?.removeChild(link);
  });
} else {
  document.body.classList.add('is-desktop');
}
