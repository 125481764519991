// Adds a line break to 'get started' boxes
// when the text only takes up a single line
const isSingleLine = (() => {
  let cachedLineHeight = null;

  const getLineHeight = el => {
    return parseInt(window.getComputedStyle(el).lineHeight);
  };

  return (el, useCachedLineHeight = true) => {
    if (!el) {
      return false;
    }

    if (useCachedLineHeight && !cachedLineHeight) {
      cachedLineHeight = getLineHeight(el);
    }

    const lineHeight =
      useCachedLineHeight && cachedLineHeight ? cachedLineHeight : getLineHeight(el);
    const height = el.offsetHeight;

    return Math.abs(height - lineHeight) < 2;
  };
})();

const getStartedText = Array.from(document.querySelectorAll('.get-started .card p'));
let prevHeight = null;

for (let text of getStartedText) {
  const height = text.offsetHeight;

  if (!prevHeight || height === prevHeight) {
    prevHeight = height;
  } else {
    getStartedText.forEach(p => {
      if (!p.innerHTML || p.classList.contains('single-line') || p.querySelector('br')) {
        return;
      }

      if (isSingleLine(p)) {
        const heading = p.parentElement.querySelector('h3');

        if (heading && !isSingleLine(heading, false)) {
          return;
        }

        const words = p.innerText.split(' ');

        if (words.length >= 5) {
          const regex = new RegExp(
            `(${words.slice(0, Math.ceil(words.length / 2)).join(' ')})`
          );
          p.innerHTML = p.innerHTML.replace(regex, '$1<br class="break-single" />');
        }
      }
    });

    break;
  }
}

// Temporarily hide post meta until
// component is updated to make optional
document.querySelector('.blog-article .post-meta')?.remove();
