import $ from 'jquery';
import { getUrlParameter } from './helpers';

const locationMapEl = document.querySelector('#location-map');

if (locationMapEl) {
  const urlLocationParam = getUrlParameter('location');
  const urlSearchParam = getUrlParameter('search');
  let isMapZoomed = false;
  let infoWindowInitialized = false;

  const $locationMap = $('#location-map');
  const $locations = $('[data-location]');
  const $locationsSearchBtn = $('#btn-locations-search');
  const $locationResetBtn = $('#btn-location-reset');
  const $locationsGeoLocatorBtn = $('#locations-geo-locator');
  const $locationListingBtns = $('.location-listings button');
  const $locationDetailBranchAddress = $('#location-branch-address');
  const $locationListingSearchInput = $('#location-listing-search #search-zipcode');
  const $locationDetailsSearch = $('#location-details-search');
  const $locationDetailsSearchInput = $('#location-details-search #search-zipcode');
  const $noSearchResults = $('.location-listings #no-search-results');
  const normalMarkerIcon = '/FremontBank/media/images/icons/icon-location-default-sm.png';
  const activeMarkerIcon = '/FremontBank/media/images/icons/icon-location-active-sm.png';

  // declare map objects
  const gmarkers = [];
  let map, markers, circle, fremontStyledMapType;

  // declare object for storing location data, elements, and markers
  let locationsData;
  const fremontCA = { lat: 37.548271, lng: -121.988571 };

  function initializeMap() {
    map = new google.maps.Map($locationMap.get(0), {
      zoom: 9,
      center: fremontCA,
      mapTypeControl: false,
      streetViewControl: false
    });

    styleGoogleMap();

    // create a marker for each location and add it to the map
    markers = $locations
      .map(function (index, domElement) {
        var locationData = $(domElement).data('location');

        var marker = new google.maps.Marker({
          position: locationData.position,
          map: map,
          icon: normalMarkerIcon,
          markerID: index
          // animation: google.maps.Animation.DROP
        });

        //When marker is clicked, display info window with location's info
        marker.addListener('click', function (e) {
          for (var j = 0; j < markers.length; j++) {
            markers[j].setIcon(normalMarkerIcon);
          }
          this.setIcon(activeMarkerIcon);

          infoWindow.setContent($(domElement).find('.location-info').html());
          infoWindow.open(map, marker);
        });

        gmarkers.push(marker);

        return marker;
      })
      .get();

    // Assign locations data
    locationsData = $locations
      .map(function (index, domElement) {
        return {
          data: $(domElement).data('location'),
          $element: $(domElement),
          marker: markers[index]
        };
      })
      .get();

    // Add missing alt tags to map images
    google.maps.event.addListener(map, 'tilesloaded', () => {
      map
        .getDiv()
        .querySelectorAll('img:not([alt])')
        .forEach(image => image.setAttribute('alt', ''));
    });
  }

  function styledMapSettings() {
    fremontStyledMapType = new google.maps.StyledMapType(
      [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off'
            }
          ]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5'
            }
          ]
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff'
            }
          ]
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada'
            }
          ]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161'
            }
          ]
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5'
            }
          ]
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9'
            }
          ]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e'
            }
          ]
        }
      ],
      { name: 'Fremont Bank Styled Map' }
    );
  }

  function styleGoogleMap() {
    map.mapTypes.set('fremont_styled_map', fremontStyledMapType);
    map.setMapTypeId('fremont_styled_map');
  }

  function registerEnterKeyOnSearchInput() {
    $($locationListingSearchInput).on('keydown', function (e) {
      if (e.key === 'Enter') {
        searchByZipRadius();
      }
    });
  }

  function handleListingItemBtn(e) {
    e.preventDefault();
    e.stopPropagation();
    const $listingBtn = $(e.currentTarget);
    const btnAddress = $listingBtn.data('address');
    const locationInfoHtml = $listingBtn.find('.location-info').html();

    if ($listingBtn.hasClass('active')) {
      $listingBtn.removeClass('active');
      return;
    }

    // state
    $locationListingBtns.not($listingBtn).removeClass('active');
    $listingBtn.addClass('active');

    activateLocation($listingBtn, btnAddress, locationInfoHtml);

    // update flag
    isMapZoomed = true;
  }

  function handleInfoWindowAfterClose() {
    setInterval(function () {
      const infoWindowOpen = infoWindow.getMap() ? true : false;

      if (!infoWindowOpen) {
        for (var j = 0; j < markers.length; j++) {
          markers[j].setIcon(normalMarkerIcon);
        }
      }

      // reset map if infoWindow is closed & `isMapZoomed` flag is `true`
      if (infoWindowInitialized && !infoWindowOpen && isMapZoomed) {
        resetMap();
      }
    }, 1000);
  }

  function handleUrlParameters() {
    // `search` parameter
    urlSearchParam !== undefined ? displayCurrentGeolocation() : initializeMap();

    // `location` parameter
    if (urlLocationParam !== undefined) {
      $locationListingSearchInput.val(urlLocationParam);
      setTimeout(() => {
        $locationsSearchBtn.trigger('click');
      }, 0);
    } else {
      initializeMap();
    }
  }

  function showNoSearchResultsMessage() {
    if ($noSearchResults) {
      $noSearchResults.removeClass('d-none');
    }
  }

  function showMapMarkersByRadius(position, radius, filteredLocationsByDistance) {
    for (var i = 0; i < gmarkers.length; i++) {
      if (
        google.maps.geometry.spherical.computeDistanceBetween(
          gmarkers[i].getPosition(),
          position
        ) < radius
      ) {
        filteredLocationsByDistance.forEach(ld => {
          if (ld.locationPosition === gmarkers[i].getPosition()) {
            gmarkers[i].setVisible(true);
            gmarkers[i].setMap(map);
          }
        });
      }
    }
  }

  function orderByRadiusDistance(position, filteredLocations, radius) {
    let numLocationWithinRadius = 0;

    // Sort all filtered locations
    var filteredLocationsByDistance = filteredLocations
      .map(ld => {
        return {
          $element: ld.$element,
          locationPosition: ld.marker.getPosition(),
          distance: google.maps.geometry.spherical.computeDistanceBetween(
            position,
            ld.marker.getPosition()
          )
        };
      })
      .sort((a, b) => a.distance - b.distance);

    if (radius > 0) {
      /* show filtered results within radius */
      hideAllLocations();
      hideAllMapMarkers(position);
      showMapMarkersByRadius(position, radius, filteredLocationsByDistance);

      // show location if within radius search
      filteredLocationsByDistance.forEach(ld => {
        const milesAway = ld.distance * 0.00062137;
        const milesAwayRounded = Math.round(milesAway * 10) / 10;
        const locationElement = ld.$element[0];
        const locationDistanceFrom = locationElement.querySelector('.distance-from');

        locationDistanceFrom.innerHTML = milesAwayRounded + ' miles away';

        if (ld.distance < radius) {
          ld.$element.show();
          numLocationWithinRadius++;
        }
      });

      // Detach and re-append locations in order of distance
      filteredLocationsByDistance.forEach(lbd => {
        var $parent = lbd.$element.parent();
        lbd.$element.detach().appendTo($parent);
      });

      $('.location-listings ul').scrollTop(0);

      if (numLocationWithinRadius === 0) {
        showNoSearchResultsMessage();
      }

      // Set location list info text
      // screenReaderAnnouncement();
    }
  }

  function searchByZipRadius(urlLocationParam) {
    var radius = 25; // 25 miles
    var radiusVal = parseInt(radius, 10) * 1600;

    var zipCitySearchVal =
      urlLocationParam !== undefined
        ? urlLocationParam
        : $($locationListingSearchInput).val();

    if (urlLocationParam !== undefined) {
      $($locationListingSearchInput).val(urlLocationParam);
    }

    if (zipCitySearchVal !== '') {
      $('.location-search .invalid-feedback').removeClass('d-block');

      geocoder.geocode(
        { address: zipCitySearchVal.toString() },
        function (results, status) {
          if (status == 'OK') {
            // reset
            infoWindow.close(); // close infoWindow if open
            $locationListingBtns.removeClass('active'); // remove state on branch listing if active
            if ($noSearchResults) {
              $noSearchResults.addClass('d-none');
            } // hide no results message

            var position = results[0].geometry.location;

            for (var i = 0; i < gmarkers.length; i++) {
              gmarkers[i].setMap(map);
            }

            map.setCenter(position);
            map.setZoom(10);

            if (circle) {
              circle.setMap(null);
            }

            circle = new google.maps.Circle({
              center: position,
              radius: radiusVal,
              strokeColor: '#d19700',
              strokeOpacity: 0.8,
              strokeWeight: 1,
              fillOpacity: 0.1,
              fillColor: '#008080',
              map: map
            });

            // Order locations by distance from position
            orderByRadiusDistance(position, locationsData, radiusVal);

            showResetBtn();
          } else {
            $('.location-search .invalid-feedback').addClass('d-block');
          }
        }
      );
    } else {
      $('.location-search .invalid-feedback').addClass('d-block');
    }
  }

  function activateLocation($listingBtn, branchAddress, infoWindowContent) {
    if (branchAddress) {
      geocoder.geocode({ address: branchAddress }, function (results, status) {
        if (status == 'OK') {
          var position = results[0].geometry.location;
          map.setCenter(position);
          map.setZoom(11);

          const $locationElement = $listingBtn.parent();
          const currentLocationData = locationsData.filter(locationData =>
            locationData.$element.is($locationElement)
          )[0];
          const marker = currentLocationData.marker;

          for (var i = 0; i < gmarkers.length; i++) {
            gmarkers[i].setIcon(normalMarkerIcon);

            if (gmarkers[i] === marker) {
              gmarkers[i].setIcon(activeMarkerIcon);
            }
          }

          if (infoWindowContent !== null) {
            infoWindow.setPosition(position);
            infoWindow.setContent(infoWindowContent);
            infoWindow.open(map);
            infoWindowInitialized = true;
          }

          styleGoogleMap();
          showResetBtn();
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  }

  function detailPageLocation(branchAddress) {
    if (branchAddress) {
      geocoder.geocode({ address: branchAddress }, function (results, status) {
        if (status == 'OK') {
          var position = results[0].geometry.location;

          map = new google.maps.Map($locationMap.get(0), {
            zoom: 14,
            center: position,
            mapTypeControl: false,
            streetViewControl: false
          });

          styleGoogleMap();

          var marker = new google.maps.Marker({
            position: position,
            map: map,
            icon: activeMarkerIcon,
            animation: google.maps.Animation.DROP
          });

          showResetBtn();
        } else {
          alert('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  }

  function handleGeoLocationError(browserHasGeolocation, infoWindow, pos) {
    const errorFailedMsg =
      '<div class="p-3"><strong class="font-weight-bold">Error</strong>: <em>The Geolocation service failed.</em> <br><br>Geographic location will only display when user has allowed location sharing in his or her browser.</div>';
    const errorBrowserNotSupported =
      '<div class="p-3"><strong class="font-weight-bold">Error</strong>: Your browser doesn\'t support geolocation.</div>';

    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation ? errorFailedMsg : errorBrowserNotSupported
    );
    infoWindow.open(map);
  }

  function displayCurrentGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          map = new google.maps.Map($locationMap.get(0), {
            zoom: 12,
            center: pos,
            mapTypeControl: false,
            streetViewControl: false
          });

          styleGoogleMap();

          var marker = new google.maps.Marker({
            position: pos,
            map: map,
            icon: activeMarkerIcon,
            animation: google.maps.Animation.DROP
          });

          showResetBtn();
          removeDistanceFromCurrentLocation();
        },
        function () {
          handleGeoLocationError(true, infoWindow, map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      handleGeoLocationError(false, infoWindow, map.getCenter());
    }
  }

  function redirectFromDetailsWithParam(searchContainer, searchVal, locationPageUrl) {
    if (searchVal !== '') {
      window.location.href = locationPageUrl + '?location=' + searchVal;
    } else {
      searchContainer.find('.invalid-feedback').addClass('d-block');
    }
  }

  function hideAllLocations() {
    locationsData.forEach(ld => {
      ld.$element.hide();
    });
  }

  function hideAllMapMarkers() {
    for (var i = 0; i < gmarkers.length; i++) {
      gmarkers[i].setMap(null);
    }
  }

  function showResetBtn() {
    $locationResetBtn.addClass('d-none').addClass('d-inline-block');
  }

  function setMobileStickyMap() {
    const headerHeight = $('header').height();
    $('.sticky-map').css('top', headerHeight + 'px');
  }

  function removeDistanceFromCurrentLocation() {
    const distanceFrom = document.querySelectorAll('.location-listings .distance-from');
    distanceFrom.forEach(function (item) {
      item.innerHTML = '';
    });
  }

  function resetMap() {
    initializeMap(); // init map

    $($locationListingSearchInput).val(''); // clear search textbox value
    $locationResetBtn // hide reset button
      .removeClass('d-inline-block')
      .addClass('d-none');

    removeDistanceFromCurrentLocation();

    $locationListingBtns.removeClass('active'); // remove active state
    $('.location-listings li').each(function () {
      // show all listings
      $(this).removeAttr('style');
    });

    const newLocationObject = locationsData
      .map(ld => {
        return {
          data: ld.data.location,
          $element: ld.$element
        };
      })
      .sort((a, b) => {
        // sort alphabetically
        if (a.data < b.data) {
          return -1;
        }
        if (a.data > b.data) {
          return 1;
        }
        return 0;
      });

    // Detach and re-append locations alphabetically
    newLocationObject.forEach(nlo => {
      var $parent = nlo.$element.parent();
      nlo.$element.detach().appendTo($parent);
    });

    if ($noSearchResults) {
      $noSearchResults.addClass('d-none'); // hide no results message
    }

    isMapZoomed = false; // reset zoom flag
  }

  // setup, initialization, event listeners
  if (locationMapEl) {
    styledMapSettings();
    registerEnterKeyOnSearchInput();
    setMobileStickyMap();
    handleInfoWindowAfterClose();
    handleUrlParameters();

    var geocoder = new google.maps.Geocoder();

    // single info window for displaying branch information when markers are clicked
    var infoWindow = new google.maps.InfoWindow({
      pixelOffset: new google.maps.Size(130, 20)
    });

    // register change event on location controls
    $locationsSearchBtn.on('click', function () {
      searchByZipRadius();
    });
    $locationsGeoLocatorBtn.on('click', displayCurrentGeolocation);
    $locationResetBtn.on('click', resetMap);
  }

  // events
  $locationListingBtns.on('click', handleListingItemBtn);

  document.addEventListener('DOMContentLoaded', function () {
    // location detail: zoom to branch on load
    if ($locationDetailBranchAddress) {
      const branchAddress = $locationDetailBranchAddress.data('branch-address');
      if (branchAddress) {
        detailPageLocation(branchAddress);
      }
    }

    // location detail: geo locator and search clicks
    if ($locationDetailsSearch) {
      const $locationDetailGeoLocatorBtn = $locationDetailsSearch.find(
        '#locations-detail-geo-locator'
      );
      const $locationDetailSearchBtn = $locationDetailsSearch.find(
        '#btn-locations-detail-search'
      );
      const locationPageUrl = $locationDetailGeoLocatorBtn.data('location-page');

      if (locationPageUrl) {
        $locationDetailGeoLocatorBtn.on('click', function () {
          window.location.href = locationPageUrl + '?search=geo-locator';
        });

        $locationDetailsSearchInput.on('keydown', function (e) {
          if (e.key === 'Enter') {
            const searchVal = $locationDetailsSearchInput.val();
            redirectFromDetailsWithParam(
              $locationDetailsSearch,
              searchVal,
              locationPageUrl
            );
          }
        });

        $locationDetailSearchBtn.on('click', function () {
          const searchVal = $locationDetailsSearchInput.val();
          redirectFromDetailsWithParam(
            $locationDetailsSearch,
            searchVal,
            locationPageUrl
          );
        });
      }
    }
  });

  // Prevent closing branch box after
  // clicking details button
  $('.location-listings button a').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    location.href = $(this).attr('href');
  });
}
