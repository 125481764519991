import initModal from './modal';
import { getUrlParameter } from './helpers';
import tracker from './analytics';

const migrationApiEndpoint = '/apis/onlinebankingapi/ismigrated';
const accounts = {
  PERSONAL: 'personal',
  BUSINESS: 'business'
};

const modal = document.querySelector('#login-modal');

/////////////////////////////////////
// BOB Migration Status
/////////////////////////////////////

const migrationStatus = modal.dataset.migrationStatus.trim().toLowerCase();

const isBeforeMigration = migrationStatus === 'before';
const isMigrationActive = migrationStatus === 'active';
const isMigrationComplete = migrationStatus === 'complete';

let businessUserRequiresMigrationCheck = isMigrationActive;
let currentBusinessUser = null;
let currentBusinessUserLoginUrl = null;

modal.removeAttribute('data-migration-status');

/////////////////////////////////////
// Open modal
/////////////////////////////////////

let usernameInput = null;

const { open } = initModal(modal, {
  trigger: '.open-login-modal',
  onOpen() {
    if (window.shouldAutofocusLogin === undefined || window.shouldAutofocusLogin) {
      setTimeout(() => usernameInput?.focus(), 0);
    }
  }
});

/////////////////////////////////////
// Forms
/////////////////////////////////////

let businessQ2LoginUrl = '';
let businessFiservLoginUrl = '';
let hasInitializedBusiness = false;
let hasBusinessScriptError = false;
let queueBusinessSubmit = false;
let hasTracked = false;

document.querySelectorAll('#personal-login-form, #business-login-form').forEach(form => {
  const isBusiness = form.id === 'business-login-form';

  if (isBusiness && !isMigrationComplete) {
    initBusinessRemoteLogin(form);
  }

  form.addEventListener(
    'submit',
    e => {
      const canSubmit =
        form.hasAttribute('data-programmatic-submit') ||
        !form.hasAttribute('data-loading');

      if (!canSubmit || !validateFields(form)) {
        e.preventDefault();
        e.stopPropagation();

        return;
      }

      form.removeAttribute('data-programmatic-submit');

      const userName = form.querySelector('.login-username');

      setFormCanSubmit(form, false);

      if (isBusiness && !isMigrationComplete) {
        if (hasBusinessScriptError) {
          // If the remote login script failed to load we
          // forward the user to the login page as a fallback
          if (businessFiservLoginUrl) {
            window.location.href = businessFiservLoginUrl;
          } else {
            console.error('Cannot find business login URL');
          }
        } else if (!hasInitializedBusiness) {
          // If the user logs in before the remote login
          // script has finished loading, we queue the
          // submission and fire it after the script inits
          queueBusinessSubmit = true;

          e.preventDefault();
          e.stopPropagation();

          return;
        }

        if (isMigrationActive && businessUserRequiresMigrationCheck) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();

          routeBusinessUserLogin(form);

          return;
        }
      }

      // Only track login if Google Tag Manager is loaded
      if (!window.disableLoginTracking && 'google_tag_data' in window && !hasTracked) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        trackLogin(userName?.value, form);
      }
    },
    true // Use capture to ensure handler runs before onsubmit
  );
});

function validateFields(form) {
  let hasErrors = false;

  form.querySelectorAll('.login-form-field-wrapper').forEach(field => {
    const input = field.querySelector('.login-form-input');
    const error = field.querySelector('.login-form-error');

    if (!input || !error) return;

    if (input.value.trim()) {
      input.setAttribute('aria-invalid', false);
      error.classList.remove('visible');
      error.setAttribute('aria-hidden', true);
    } else {
      hasErrors = true;
      input.setAttribute('aria-invalid', true);
      error.classList.add('visible');
      error.setAttribute('aria-hidden', false);
    }
  });

  return !hasErrors;
}

function submitForm(form) {
  form.setAttribute('data-programmatic-submit', 'true');

  // Calling form.submit() doesn't fire event listeners,
  // so instead we programmatically click the submit button
  const loginButton = form.querySelector('.login-button');

  // Ensure button is enabled so that click is triggered
  // The submit handler will disable the button again. We
  // don't reenable it here because it would fire after the
  // submit handler and might be in the incorrect state.
  loginButton.disabled = false;
  loginButton.click();
}

function setFormCanSubmit(form, canSubmit) {
  const submitButton = form.querySelector('.login-button');

  if (canSubmit) {
    form.removeAttribute('data-loading');
    submitButton?.removeAttribute('disabled');
    submitButton?.classList.remove('loading');
  } else {
    form.setAttribute('data-loading', 'true');
    submitButton?.setAttribute('disabled', true);
    submitButton?.classList.add('loading');
  }
}

function handleLoginError(form, shouldEnableSubmit = true) {
  businessUserRequiresMigrationCheck = true;
  currentBusinessUserLoginUrl = null;
  form.removeAttribute('data-programmatic-submit');

  const error = form.querySelector('.login-form-general-error');

  error?.classList.remove('hidden');
  error?.setAttribute('aria-hidden', false);

  if (shouldEnableSubmit) {
    setFormCanSubmit(form, true);
  }
}

// Loads and inits business online banking script
function initBusinessRemoteLogin(form) {
  const url = form.action || '';
  const { app = '', script: scriptSrc = '', q2LoginUrl = '' } = form.dataset;

  form.removeAttribute('data-type');
  form.removeAttribute('data-app');
  form.removeAttribute('data-script');
  form.removeAttribute('data-q2-login-url');

  businessQ2LoginUrl = q2LoginUrl.trim();
  businessFiservLoginUrl = url.trim();

  form.removeAttribute('action');

  if (!scriptSrc.trim()) {
    console.error('Business remote login script not found');
    return;
  }

  let submittedUsername = null;

  const handleLoad = () => {
    hasInitializedBusiness = true;

    new EBC.RemoteLogin({
      applicationPath: app.trim(),
      routingTransit: '121107882',
      formId: 'business-login-form',
      usernameId: 'login-username-business',
      passwordId: 'login-password-business',
      submitCallback: () => {
        submittedUsername = form.querySelector('.login-username').value.trim();
      },
      errorCallback: () => {
        if (isMigrationActive && submittedUsername !== currentBusinessUser) {
          return;
        }

        if (isBeforeMigration && businessFiservLoginUrl) {
          window.location.href = businessFiservLoginUrl;
        } else if (isMigrationActive && currentBusinessUserLoginUrl) {
          window.location.href = currentBusinessUserLoginUrl;
        } else {
          handleLoginError(form);
        }
      }
    });

    fiservOnSubmitFn = form.onsubmit;

    // During migration prevent Fiserv submit handler
    // from firing until we've checked the user's status
    if (isMigrationActive) form.onsubmit = null;

    if (queueBusinessSubmit) {
      queueBusinessSubmit = false;
      submitForm(form);
    }
  };

  const handleError = (
    error,
    message = 'Unable to load business remote login script'
  ) => {
    hasBusinessScriptError = true;
    console.error(message, scriptSrc, error);
  };

  try {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.onload = handleLoad;
    script.onerror = handleError;

    if (document.currentScript) {
      document.currentScript.parentNode.insertBefore(script, document.currentScript);
    } else {
      document.body.appendChild(script);
    }
  } catch (error) {
    handleError(error);
  }
}

/////////////////////////////////////
// BOB Migration API
/////////////////////////////////////

let fiservOnSubmitFn = null;

async function routeBusinessUserLogin(form, isRetry) {
  const usernameInput = form.querySelector('.login-username');
  const username = usernameInput.value.trim();

  currentBusinessUser = username;

  try {
    // Check if user has been migrated
    const url = new URL(migrationApiEndpoint, window.location.origin);
    url.searchParams.append('user', username);

    const response = await fetch(url);
    const data = await response.json();
    const isQ2User = data?.isMigrated ?? true;

    if (username === currentBusinessUser) {
      // Update form to point to correct website
      if (isQ2User) {
        currentBusinessUserLoginUrl = businessQ2LoginUrl;
        form.action = businessQ2LoginUrl;
        form.onsubmit = null;
        usernameInput.name = 'user_id';
      } else {
        currentBusinessUserLoginUrl = businessFiservLoginUrl;
        form.removeAttribute('action');
        form.onsubmit = fiservOnSubmitFn;
        usernameInput.name = 'username';
      }

      // Set flag to prevent multiple checks and resubmit form
      businessUserRequiresMigrationCheck = false;
      submitForm(form);
    }
  } catch (e) {
    if (!isRetry) {
      setTimeout(() => routeBusinessUserLogin(form, true), 300);
      return;
    }

    console.error(e);

    if (username === currentBusinessUser) {
      handleLoginError(form);
    }
  }
}

/////////////////////////////////////
// Account type toggles
/////////////////////////////////////

let defaultAccountType = localStorage.getItem('default-account-type');

const accountTypeToggle = modal.querySelector('#account-type-toggle');
const saveAccountTypeWrapper = modal.querySelector('.save-account-type');
const saveAccountTypeCheckbox = saveAccountTypeWrapper?.querySelector('input');

function isValidAccountType(type) {
  return type === accounts.PERSONAL || type === accounts.BUSINESS;
}

function updateDefaultAccountTypeBox(accountType) {
  if (!accountTypeToggle || !saveAccountTypeCheckbox) {
    return;
  }

  if (!isValidAccountType(accountType)) {
    modal.classList.remove('save-account-type-visible');
    return;
  }

  saveAccountTypeCheckbox.checked = accountType === defaultAccountType;

  const label = saveAccountTypeWrapper.querySelector('label');
  label.innerHTML = `Set ${accountType} <span>online banking</span> as your default login`;
}

updateDefaultAccountTypeBox(defaultAccountType);

if (accountTypeToggle && isValidAccountType(defaultAccountType)) {
  accountTypeToggle.value = defaultAccountType;
}

usernameInput = accountTypeToggle
  ? modal.querySelector('.login-form-panel.active .login-username')
  : modal.querySelector('.login-form-panel .login-username');

let accountTypeResizeObserver = null;

if (accountTypeToggle) {
  accountTypeToggle.addEventListener('change', () => {
    updateDefaultAccountTypeBox(accountTypeToggle.value);
    modal.classList.add('save-account-type-visible');

    if (accountTypeResizeObserver) {
      accountTypeResizeObserver.disconnect();
      saveAccountTypeWrapper.style.marginBottom = null;
    }

    usernameInput = modal.querySelector('.login-form-panel.active .login-username');
  });

  saveAccountTypeCheckbox.addEventListener('change', () => {
    if (saveAccountTypeCheckbox.checked) {
      defaultAccountType = accountTypeToggle.value;
      localStorage.setItem('default-account-type', accountTypeToggle.value);
    } else if (defaultAccountType === accountTypeToggle.value) {
      defaultAccountType = null;
      localStorage.removeItem('default-account-type');
    }
  });
}

// Open the modal if immediately if login URL
// parameter equals an account type or "true"
let loginUrlParameter = getUrlParameter('login')?.toLowerCase();

if (loginUrlParameter) {
  if (accountTypeToggle && isValidAccountType(loginUrlParameter)) {
    accountTypeToggle.value = loginUrlParameter;
    open();
  } else if (loginUrlParameter === 'true') {
    open();
  }
}

if (saveAccountTypeWrapper && window.ResizeObserver) {
  let timer = null;

  accountTypeResizeObserver = new ResizeObserver(([{ contentRect }]) => {
    const height = Math.round(contentRect.height);
    saveAccountTypeWrapper.style.transitionProperty = 'none';
    saveAccountTypeWrapper.style.marginBottom = `-${height}px`;

    clearTimeout(timer);

    timer = setTimeout(() => {
      saveAccountTypeWrapper.style.transitionProperty = null;
    }, 10);
  });

  accountTypeResizeObserver.observe(saveAccountTypeWrapper);
}

/////////////////////////////////////
// Show/hide password
/////////////////////////////////////

modal.querySelectorAll('.password-input').forEach(wrapper => {
  const input = wrapper.querySelector('.login-password');
  const button = wrapper.querySelector('.toggle-password');

  if (!input || !button) return;

  const tooltip = button.querySelector('.fb-tooltip');

  button.addEventListener('click', () => {
    const willShow = input.type === 'password';

    wrapper.classList.toggle('password-visible', willShow);
    input.type = willShow ? 'text' : 'password';
    button.setAttribute('aria-checked', willShow);

    if (tooltip) {
      tooltip.innerText = willShow ? 'Hide password' : 'Show password';
    }
  });
});

/////////////////////////////////////
// Analytics
/////////////////////////////////////

async function trackLogin(userName, form) {
  if (hasTracked) return;

  hasTracked = true;

  tracker.track('login', {
    username: userName,
    fireImmediately: true,
    timeout: 500,
    callback: () => submitForm(form)
  });

  const supportsKeepAlive = window.Request && 'keepalive' in new Request('');
  const url = '/apis/activityapi/accountlogin';
  const body = JSON.stringify({ userName });

  try {
    if (supportsKeepAlive) {
      fetch(url, {
        body,
        method: 'post',
        keepalive: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      });
    } else if ('sendBeacon' in navigator) {
      navigator.sendBeacon(url, body);
    }
  } catch (e) {
    console.error(e);
  }
}
