import { uniqueId } from 'lodash';
import { createElement } from './helpers';
import initModal from './modal';

const DEFAULT_INIT_DELAY = 250;

export function initIframeModal(trigger, opts = {}) {
  const { url, title, iframeDelay, hasDelay } = setIframeModalVars(trigger, opts);
  if (!url) {
    console.warn('Iframe url not found');
    return;
  }

  const id = uniqueId();
  const labelId = `iframe-modal-title-${id}`;
  const wrapper = createElement('dialog', {
    id: `iframe-modal-${id}`,
    className: 'fb-modal-wrapper iframe-modal-wrapper'
  });
  const modal = createElement('div', { className: 'fb-modal' });
  const modalContent = createElement('div', { className: 'fb-modal-content' });
  const titleEl = createElement('div', { className: 'fb-modal-top' });
  const titleText = createElement('span', { id: labelId, innerText: title });
  const closeButton = createElement('button', {
    className: 'close-button',
    innerHTML: '<span class="sr-only">Close</span>'
  });
  const iframeWrapper = createElement('div', { className: 'iframe-wrapper' });
  const iframe = createElement('iframe', {
    className: 'modal-iframe',
    attributes: [
      ['scrolling', 'yes'],
      [!hasDelay ? 'src' : 'data-src', url]
    ]
  });

  titleEl.appendChild(titleText);
  titleEl.appendChild(closeButton);
  iframeWrapper.appendChild(iframe);
  modalContent.appendChild(titleEl);
  modalContent.appendChild(iframeWrapper);
  modal.appendChild(modalContent);
  wrapper.appendChild(modal);
  document.body.appendChild(wrapper);

  if (hasDelay) {
    setTimeout(() => {
      iframe.setAttribute('src', url);
      iframe.removeAttribute('data-src');
    }, iframeDelay);
  }

  const modalOptions = {
    trigger,
    isTriggerLink: trigger.tagName === 'A',
    addCloseButton: false,
    onOpen: opts.onOpen,
    onClose: opts.onClose
  };

  return initModal(wrapper, modalOptions);
}

function setIframeModalVars(trigger, opts) {
  const url = opts.url ?? trigger.dataset.iframeModal;
  const title = opts.title ?? trigger.dataset.modalTitle ?? trigger.innerText;
  let iframeDelay = opts.iframeDelay ?? trigger.dataset.iframeDelay;
  iframeDelay = parseInt(iframeDelay ?? DEFAULT_INIT_DELAY);
  const hasDelay = !isNaN(iframeDelay) && iframeDelay > 0;

  trigger.removeAttribute('data-iframe-delay');
  trigger.removeAttribute('data-modal-title');

  return { url, title, iframeDelay, hasDelay };
}

document.querySelectorAll('[data-iframe-modal]').forEach(initIframeModal);
