import { getScrollbarWidth } from '../scrolling';

// Create an HTMLLinkElement for a given result
export function createResult({
  content,
  resultElement,
  category,
  path: url,
  pageName: name
}) {
  if (resultElement) {
    return resultElement;
  }

  url = url.toLowerCase();

  const el = document.createElement('a');
  el.className = 'search-result';
  el.href = url;

  const top = document.createElement('div');
  top.className = 'search-result-top';

  const title = document.createElement('strong');
  title.className = 'search-result-title';
  title.innerText = name ?? `${location.hostname}${url}`;
  top.appendChild(title);

  // If no category data was passed from API, we
  // attempt to find a category label from the URL
  category ??= resultCategories.find(({ path }) => {
    const regex = new RegExp(`^${path}`, 'i');
    return url.match(regex);
  });

  if (category) {
    const categoryEl = document.createElement('div');

    categoryEl.classList.add('search-result-category');
    categoryEl.classList.add(category.cssClassName);
    categoryEl.innerText = category.text.trim();

    top.appendChild(categoryEl);
  }

  el.appendChild(top);

  content = content?.trim();

  if (content) {
    const text = document.createElement('p');
    text.innerHTML = content;
    el.appendChild(text);
  }

  const urlEl = document.createElement('div');
  urlEl.className = 'search-result-url';
  urlEl.innerText = `${location.protocol}//${location.hostname}${url}`;
  el.appendChild(urlEl);

  return el;
}

const resultCategories = [
  { path: '/personal', text: 'Personal Banking', cssClassName: 'personal' },
  { path: '/business', text: 'Business Banking', cssClassName: 'business' },
  {
    path: '/wealth-management',
    text: 'Wealth Management',
    cssClassName: 'wealth-management'
  },
  { path: '/why-choose-us', text: 'About Fremont Bank', cssClassName: 'about' },
  {
    path: '/resource-center',
    text: 'Resource Center',
    cssClassName: 'resource-center'
  }
];

// Create top search result elements
export function createTopResult({
  nodeId,
  title,
  subtitle,
  content,
  resultCss,
  cssClassName: className
}) {
  // Add inline CSS
  if (resultCss && !topResultStyleIds.includes(nodeId)) {
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.appendChild(document.createTextNode(`\n${resultCss}\n`));

    document.head.appendChild(style);
    topResultStyleIds.push(nodeId);
  }

  // Generate reult markup
  const result = document.createElement('div');
  result.className = 'best-bet-search-result';

  const top = document.createElement('div');
  top.className = 'search-result-top';

  const titleWrapper = document.createElement('div');

  const titleEl = document.createElement('strong');
  titleEl.className = 'search-result-title';
  titleEl.innerText = title;
  titleWrapper.appendChild(titleEl);

  subtitle = subtitle?.trim();

  if (subtitle) {
    const subtitleEl = document.createElement('div');
    subtitleEl.className = 'search-result-subtitle';
    subtitleEl.innerText = subtitle;
    titleWrapper.appendChild(subtitleEl);
  }

  top.appendChild(titleWrapper);

  const category = document.createElement('div');
  category.className = 'search-result-category best-bet';
  category.innerText = 'Top result';
  top.appendChild(category);

  const contentEl = document.createElement('div');
  contentEl.classList.add('search-result-content');
  contentEl.innerHTML = content;

  if (className) {
    contentEl.classList.add(className);
  }

  result.appendChild(top);
  result.appendChild(contentEl);

  return result;
}

// Best bet results can contain custom inline CSS to
// for that particular result that gets append
// to the DOM. We keep track of which results we have
// appended CSS for to avoid adding duplicated style tags
const topResultStyleIds = [];

// Create best bet and regular search result headlines
export function createResultsTitle(isBestBet) {
  const el = document.createElement('div');
  el.className = 'search-results-headline';

  const text = document.createElement('span');
  text.className = 'text';

  if (isBestBet) {
    el.classList.add('best-bet');
    text.innerText = 'Top search results:';
  } else {
    text.innerText = 'Search results for:';
  }

  el.appendChild(text);

  const query = document.createElement('span');
  query.className = 'query';

  el.appendChild(query);

  return { el, text, query };
}

// Create load more results button element
export function createLoadMoreButton(text) {
  const button = document.createElement('button');
  button.className = 'btn btn-outline-primary large search-load-more';
  button.innerText = text;

  return button;
}

// Create best bet search results wrapper
export function createBestBetWrapper() {
  const wrapper = document.createElement('div');
  wrapper.className = 'best-bet-results-wrapper';

  return wrapper;
}

// Create best best bet links wrapper
export function createBestBetLinksWrapper() {
  const wrapper = document.createElement('div');
  wrapper.className = 'best-bet-results';

  return wrapper;
}

// Creates animation elements and adjusts position of
// elements and close button to account for scrollbars
export function createAndPositionFixedElements(closeButton) {
  const bg = document.createElement('div');
  bg.className = 'search-bg';

  const topShadow = document.createElement('div');
  topShadow.className = 'search-top-shadow';

  const scrollbarWidth = getScrollbarWidth();

  if (scrollbarWidth) {
    const offset = `${scrollbarWidth}px`;

    bg.style.right = offset;
    closeButton.style.marginRight = offset;
    topShadow.style.right = offset;
  }

  return { bg, topShadow };
}

// We cache results of search queries to prevent
// redundant API calls. This function generates an
// ID for a specific query. This allows us to ignore
// punctuation, capitalization and whitespace when caching.
// Original hash function: https://stackoverflow.com/a/52171480
export function generateId(str, seed = 0) {
  str = str
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9]/gi, '');

  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return String(4294967296 * (2097151 & h2) + (h1 >>> 0));
}
