import $ from 'jquery';

function spaceBarTriggerAbstractModal(modalTarget) {
  $(modalTarget).modal();
}

function handleModalBtnLink(e) {
  const modalBtnLink = e.currentTarget;
  const modalTarget = modalBtnLink.dataset.target;

  // ally: handle <a> with `role="button"`
  if (e.key === ' ') {
    e.preventDefault(); // prevent space bar vertical scroll
    spaceBarTriggerAbstractModal(modalTarget); // trigger modal on SPACEBAR
  }
}

const modalBtnLinks = document.querySelectorAll('.modal-button-link');

modalBtnLinks.forEach(function (modalBtnLink) {
  modalBtnLink.addEventListener('keydown', handleModalBtnLink);
});

// Allow triggering modal from links without the data-target attribute
const modalSelectors = ['abstractModal', 'requestFormModal']
  .map(s => `a[href*='${s}']`)
  .join(', ');

document.querySelectorAll(modalSelectors).forEach(link => {
  if (link.classList.contains('btn')) {
    const button = document.createElement('button');
    button.dataset.toggle = 'modal';
    button.dataset.target = link.getAttribute('href');
    button.innerHTML = link.innerHTML;
    button.className = link.className;

    link.parentElement.replaceChild(button, link);
  } else if (!link.getAttribute('data-target')) {
    link.setAttribute('role', 'button');
    link.dataset.toggle = 'modal';
    link.dataset.target = link.getAttribute('href');
  }
});
