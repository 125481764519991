const header = document.querySelector('#header');

if (header && document.body.classList.contains('homepage')) {
  if (window.IntersectionObserver) {
    const content = document.querySelector('#main section, #main div');

    if (content) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            header.classList.add('no-bg');
          } else {
            header.classList.remove('no-bg');
          }

          header.classList.add('loaded');
        },
        { threshold: 1, rootMargin: '15px 0px 0px 0px' }
      );

      observer.observe(content);
    }
  } else {
    header.classList.add('loaded');
  }
}
