import { snakeCase } from 'lodash';

export class Tracker {
  #defaultEventName = 'generic_event';

  constructor(defaultEventName) {
    if (defaultEventName) {
      this.#defaultEventName = defaultEventName;
    }

    this.track = this.track.bind(this);
  }

  track(eventName = this.#defaultEventName, eventParams = {}, eventCallback) {
    const {
      callback = eventCallback,
      timeout = 2000,
      minCallbackTimeout,
      fireImmediately = false,
      ...customParams
    } = eventParams;

    const gtag =
      window.gtag ||
      function () {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
      };

    const callbackWithTimeout = () => {
      let hasCalled = false;
      let hasReachedMinTimeout = isNaN(minCallbackTimeout) || minCallbackTimeout === 0;

      const fn = () => {
        if (callback && !hasCalled) {
          hasCalled = true;

          if (hasReachedMinTimeout) {
            callback();
          }
        }
      };

      setTimeout(fn, timeout);

      if (!hasReachedMinTimeout) {
        setTimeout(() => {
          hasReachedMinTimeout = true;

          if (hasCalled && callback) {
            callback();
          }
        }, minCallbackTimeout);
      }

      return fn;
    };

    const fireEvent = () => {
      const params = {
        event_callback: callbackWithTimeout(),
        event_timeout: timeout
      };

      Object.keys(customParams).forEach(key => {
        params[snakeCase(key)] = customParams[key];
      });

      gtag('event', eventName, params);
    };

    if (fireImmediately) {
      fireEvent();
    } else {
      requestIdleCallback(fireEvent, { timeout: 5000 });
    }
  }
}

const tracker = new Tracker();

export default tracker;
