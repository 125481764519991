import { snakeCase } from 'lodash';

const actions = Object.freeze({
  GET_RESULTS: 'search',
  NO_RESULTS: 'no_search_results',
  SUGGESTED_TERM: 'click_suggested_search',
  UNSATISFACTORY_RESULTS: 'click_unsatisfactory_search'
});

export default class SearchTracker {
  #trackTimer = null;
  #feedbackButtonText = 'Let us know';

  constructor(manager) {
    this.manager = manager;

    this.fireEvent = this.fireEvent.bind(this);
    this.track = this.track.bind(this);
    this.insertFeedbackElements = this.insertFeedbackElements.bind(this);
    this.setFeedbackText = this.setFeedbackText.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.refresh = this.refresh.bind(this);
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.#initElements();
  }

  /////////////////////////////////////
  // Search tracking actions
  /////////////////////////////////////

  static get actions() {
    return actions;
  }

  /////////////////////////////////////
  // Public methods
  /////////////////////////////////////

  fireEvent(options, callback) {
    const {
      action,
      eventParams = {},
      callbackTimeout = 2000,
      minCallbackTimeout
    } = options;

    const gtag =
      window.gtag ||
      function () {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
      };

    // Even though GTM allows callbackTimeout parameter,
    // we manually add a timeout in case GTM didn't load
    const callbackWithTimeout = () => {
      let hasCalled = false;
      let hasReachedMinTimeout = isNaN(minCallbackTimeout) || minCallbackTimeout === 0;

      const fn = () => {
        if (callback && !hasCalled) {
          hasCalled = true;

          if (hasReachedMinTimeout) {
            callback();
          }
        }
      };

      setTimeout(fn, callbackTimeout);

      if (!hasReachedMinTimeout) {
        setTimeout(() => {
          hasReachedMinTimeout = true;

          if (hasCalled && callback) {
            callback();
          }
        }, minCallbackTimeout);
      }

      return fn;
    };

    requestIdleCallback(
      () => {
        const params = {
          event_callback: callbackWithTimeout(),
          event_timeout: callbackTimeout
        };

        Object.keys(eventParams).forEach(key => {
          params[snakeCase(key)] = eventParams[key];
        });

        gtag('event', action, params);
      },
      { timeout: 5000 }
    );
  }

  track(action = SearchTracker.actions.GET_RESULTS, eventParams, forceTrackEvent) {
    eventParams ??= {};

    if (!forceTrackEvent) {
      clearTimeout(this.#trackTimer);
    }

    const data = this.manager.currentQuery;
    const query = data.query?.trim().toLowerCase();

    if (
      !data ||
      !query ||
      (action === actions.GET_RESULTS && data.hasTrackedQuery) ||
      (action === actions.NO_RESULTS && data.hasTrackedNoResults) ||
      (action === actions.SUGGESTED_TERM && data.hasTrackedSuggestedTerm)
    ) {
      return;
    }

    if (!eventParams.hasOwnProperty('search_term')) {
      eventParams.search_term = query;
    }

    const trackEvent = () => {
      if (action === actions.GET_RESULTS) {
        data.hasTrackedQuery = true;
      } else if (action === actions.NO_RESULTS) {
        data.hasTrackedNoResults = true;
      } else if (action === actions.SUGGESTED_TERM) {
        data.hasTrackedSuggestedTerm = true;
      }

      this.fireEvent({ action, eventParams });
    };

    if (forceTrackEvent) {
      trackEvent();
    } else {
      this.#trackTimer = setTimeout(trackEvent, this.manager.queryTrackingTimeout);
    }
  }

  insertFeedbackElements(parentElement) {
    parentElement.appendChild(this.feedback);
  }

  setFeedbackText(searchSettings) {
    const settings = searchSettings ?? this.manager.searchSettings ?? {};

    if (!settings) {
      return;
    }

    let {
      feedbackTitle = 'Didn’t find what you were looking for?',
      feedbackText = 'Your feedback will help us provide a better search experience.',
      feedbackButtonText = 'Let us know',
      feedbackSentText = 'Thank you for your feedback'
    } = settings;

    // Before feedback sent
    this.feedbackTitle.innerText = feedbackTitle;
    this.feedbackText.innerHTML = feedbackText
      .split(/\n+/)
      .map(t => {
        t = t.trim();
        return t ? `<p>${t}</p>` : null;
      })
      .filter(t => t)
      .join('\n');

    this.#feedbackButtonText = feedbackButtonText.trim();
    this.feedbackButton.innerText = this.#feedbackButtonText;

    // After feedback sent
    feedbackSentText = feedbackSentText.trim();

    if (feedbackSentText) {
      this.feedbackSentText.innerText = feedbackSentText;
      this.feedbackSent.appendChild(this.feedbackSentText);
    } else {
      this.feedbackSent.removeChild(this.feedbackSentText);
    }
  }

  sendFeedback(query) {
    const data = this.manager.currentQuery;

    if (!query || typeof query !== 'string') {
      query = data?.query;
    }

    query = query?.trim();

    if (!data || data.hasSubmittedFeedback || !query) {
      return;
    }

    data.hasSubmittedFeedback = true;

    const eventConfig = {
      action: actions.UNSATISFACTORY_RESULTS,
      minCallbackTimeout: 500,
      eventParams: {
        search_term: query.toLowerCase()
      }
    };

    this.feedbackButton.classList.add('loading');
    this.feedbackButton.innerText = this.manager.loadMoreIsLoadingText;

    this.fireEvent(eventConfig, () => {
      this.feedback.classList.add('submitted');
    });
  }

  refresh(isVisible) {
    if (isVisible === undefined) {
      const query = this.manager.currentQuery;
      isVisible = !query?.hasMoreResults && !query?.hasSubmittedFeedback;
    }

    this.feedback.classList.remove('submitted');
    this.feedbackButton.classList.remove('loading');
    this.feedbackButton.innerText = this.#feedbackButtonText;

    if (isVisible) {
      this.feedback.classList.add('visible');
    } else {
      this.feedback.classList.remove('visible');
    }
  }

  show() {
    this.refresh(true);
  }

  hide() {
    this.refresh(false);
  }

  /////////////////////////////////////
  // Private methods
  /////////////////////////////////////

  #initElements() {
    this.feedback = document.createElement('div');
    this.feedback.className = 'search-feedback-wrapper';

    // Elements displayed before feedback
    // for a given query has been sent
    const sendFeedback = document.createElement('div');
    sendFeedback.className = 'search-feedback';

    this.feedbackTitle = document.createElement('div');
    this.feedbackTitle.className = 'search-feedback-title';
    sendFeedback.appendChild(this.feedbackTitle);

    this.feedbackText = document.createElement('div');
    this.feedbackText.className = 'search-feedback-text';
    sendFeedback.appendChild(this.feedbackText);

    this.feedbackButton = document.createElement('button');
    this.feedbackButton.className = 'btn btn-outline-primary search-feedback-button';
    this.feedbackButton.addEventListener('click', this.sendFeedback);
    sendFeedback.appendChild(this.feedbackButton);

    // Elements displayed after feedback has been sent
    this.feedbackSent = document.createElement('div');
    this.feedbackSent.className = 'search-feedback-sent';
    this.feedbackSent.innerHTML = `
      <svg class="search-feedback-checkmark" viewBox="0 0 52 52">
        <circle cx="26" cy="26" r="25" fill="none"/>
        <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    `;

    this.feedbackSentText = document.createElement('p');
    this.feedbackSent.appendChild(this.feedbackSentText);

    const contentWrapper = document.createElement('div');
    contentWrapper.className = 'search-feedback-content';
    contentWrapper.appendChild(sendFeedback);
    contentWrapper.appendChild(this.feedbackSent);
    this.feedback.appendChild(contentWrapper);

    this.setFeedbackText();
  }
}
